import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import checkUser from '../UserComponent';
import { Route, Switch } from 'react-router-dom';
import ExampleCreate from './ExampleCreate';
import ExampleMain from './ExampleMain';
import ExampleView from './ExampleView';

class ExampleRootComp extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  render() {
    const { match } = this.props;
    return (
      <div className="entity-wrapper mt-3">
        <Switch>
          <Route exact path={match.path} component={ExampleMain} />
          <Route exact path={`${match.path}/create`} component={ExampleCreate} />
          <Route exact path={`${match.path}/:id`} component={ExampleView} />
        </Switch>
      </div>
    );
  }
}

const ExampleRoot = checkUser(ExampleRootComp);

export default ExampleRoot;
