import { deleteData, getData, postData } from '../../utils/httputils';

class Modules {
  static apiPath = '/api/v1/modules';
  static adminApiPath = '/api/v1/admin/modules';

  static getModules() {
    return getData(Modules.apiPath);
  }

  static create(data) {
    return postData(Modules.adminApiPath, data);
  }

  static find(params) {
    const { limit, offset, order_by, sort, search } = params;
    const searchQuery = search ? '&search=' + search : '';
    return getData(
      `${Modules.adminApiPath}?limit=${limit || 10}&offset=${offset || 0}&order_by=${order_by || 'id'}&sort=${sort ||
        'desc'}${searchQuery}`
    );
  }

  static delete = code => {
    return deleteData(`${Modules.adminApiPath}/${code}`);
  };
}

export default Modules;
