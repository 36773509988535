import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { setTitle } from '../../lib/utils/windowutils';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import AdminMain from './AdminMain';
import Distributor from '../Distributor/';
import Customer from '../Customer';
import Site from '../Site';
import ModuleRoot from '../Module';
import CategoryRoot from '../Category';
import DomainRoot from '../Domain';
import AccountRoot from '../Accounts';

class AdminComp extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    setTitle('Admin');
  }

  render() {
    const { match } = this.props;
    return (
      <div className="container-fluid main-wrapper">
        <Switch>
          <Route exact path={match.path} component={AdminMain} />
          <Route path={`${match.path}/distributors`} component={Distributor} />
          <Route path={`${match.path}/customers`} component={Customer} />
          <Route path={`${match.path}/sites`} component={Site} />
          <Route path={`${match.path}/modules`} component={ModuleRoot} />
          <Route path={`${match.path}/categories`} component={CategoryRoot} />
          <Route path={`${match.path}/domains`} component={DomainRoot} />
          <Route path={`${match.path}/accounts`} component={AccountRoot} />
        </Switch>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;

  return { user };
}

const AdminRoot = connect(mapStateToProps)(AdminComp);
export default AdminRoot;
