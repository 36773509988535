import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Loading from '../Common/Loading';
import DescriptionEdit from '../Common/DescriptionEdit';
import { handleOnKeyDown } from '../../lib/utils/commonutils';
import ReactMarkdown from 'react-markdown';
import { setTitle } from '../../lib/utils/windowutils';
import Categories from '../../lib/models/categories/categories';
import { ltToast } from '../Common/LTToast';
import { Link } from 'react-router-dom';

class CategoryView extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  state = {
    category: false,
    editField: '',
    editValue: '',
    editing: false,
    currentCustomer: -1
  };

  refInput = React.createRef();

  render() {
    const { category, editing, editField, editValue } = this.state;
    if (!category) {
      return <Loading />;
    }
    return (
      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/admin/">Admin</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/categories">Categories</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Edit
            </li>
          </ol>
        </nav>
        <h5 className="mb-3">Edit Category</h5>
        <div className="row">
          <div className="mb-3 col-12 col-md-6 col-lg-4">
            <div className="card border-info">
              <div className="card-header bg-info text-white">
                <strong>Detail</strong>
              </div>
              <div className="card-body">
                <dl className="row">
                  <dt className="col-12 col-md-4 col-lg-3">Code</dt>
                  <dd
                    className="col-12 col-md-8 col-lg-9"
                    onClick={e => this.setState({ editField: 'code', editValue: category.code, editing: true })}
                  >
                    {(!editing || editField !== 'code') && <DescriptionEdit>{category.code}</DescriptionEdit>}
                    {editing && editField === 'code' && (
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        value={editValue}
                        ref={this.refInput}
                        onBlur={this.saveEdit}
                        onKeyDown={e => handleOnKeyDown(e, this.saveEdit)}
                        onChange={e => this.setState({ editValue: e.target.value })}
                      />
                    )}
                  </dd>
                  <dt className="col-12 col-md-4 col-lg-3">Description</dt>
                  <dd
                    className="col-12 col-md-8 col-lg-9"
                    onClick={e =>
                      this.setState({ editField: 'description', editValue: category.description, editing: true })
                    }
                  >
                    {(!editing || editField !== 'description') && (
                      <DescriptionEdit>
                        <ReactMarkdown source={category.description} />
                      </DescriptionEdit>
                    )}
                    {editing && editField === 'description' && (
                      <div>
                        <span>
                          Use Markdown:{' '}
                          <a
                            href="https://en.support.wordpress.com/markdown-quick-reference/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Help
                          </a>
                        </span>
                        <textarea
                          className="form-control form-control-sm"
                          value={editValue}
                          autoCorrect="off"
                          rows={5}
                          ref={this.refInput}
                          onBlur={this.saveEdit}
                          onChange={e => this.setState({ editValue: e.target.value })}
                        />
                      </div>
                    )}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mb-3 col-12 col-md-6 col-lg-4">
            <div className="card border-danger">
              <div className="card-header bg-danger text-white">
                <strong>DANGER ZONE</strong>
              </div>
              <div className="card-body">
                <button className="btn btn-danger" onClick={this.handleDelete}>
                  DELETE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.loadCategory();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.category && this.state.category.id && !prevState.category.id) {
      setTitle(this.state.category.code);
    }
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.loadCategory();
    }
    if (!prevState.editing && this.state.editing && this.refInput.current) {
      this.refInput.current.focus();
    }
  }

  handleDelete = e => {
    e.preventDefault();
    const ok = window.confirm('Are you sure?');
    if (ok) {
      const { category } = this.state;
      Categories.delete(category.id)
        .then(() => {
          this.props.history.push('/admin/categories');
        })
        .catch(e => {
          ltToast('Unable to delete entity: ' + e.message, 5000, true);
        });
    }
  };

  saveEdit = () => {
    const { editField, editValue, category } = this.state;
    this.setState({ editField: '', editValue: '', editing: false });
    this.refInput = React.createRef();
    if (category[editField] !== editValue) {
      Categories.update(category.id, editField, editValue)
        .then(category => {
          this.setState({ category });
        })
        .catch(e => {
          ltToast('Unable to save: ' + e.message, 5000, true);
        });
    }
  };

  loadCategory = () => {
    const { match } = this.props;
    const { params } = match;
    Categories.get(params.id)
      .then(category => {
        this.setState({
          category
        });
      })
      .catch(e => console.error('err', e.message));
  };
}

export default CategoryView;
