import { combineReducers } from 'redux';
import { userReducer } from './lib/models/user/';
import { settingsReducer } from './lib/models/settings/';
import { sitesReducer } from './lib/models/sites/';
import { categoriesReducer } from './lib/models/categories/';
import { modulesReducer } from './lib/models/modules';
import { connectRouter } from 'connected-react-router';

const rootReducer = history =>
  combineReducers({
    user: userReducer,
    settings: settingsReducer,
    sites: sitesReducer,
    categories: categoriesReducer,
    modules: modulesReducer,
    router: connectRouter(history)
  });

export default rootReducer;
