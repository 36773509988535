import { deleteData, getData, postData, putData } from '../utils/httputils';

class Distributors {
  static apiPath = '/api/v1/admin/distributors';

  static create(data) {
    return postData(Distributors.apiPath, data);
  }

  static find(params) {
    const { limit, offset, order_by, sort, search } = params;
    const searchQuery = search ? '&search=' + search : '';
    return getData(
      `${Distributors.apiPath}?limit=${limit || 10}&offset=${offset || 0}&order_by=${order_by || 'id'}&sort=${sort ||
        'desc'}${searchQuery}`
    );
  }

  static get(id) {
    return getData(`${Distributors.apiPath}/${id}`);
  }

  static update(id, field, value) {
    return putData(`${Distributors.apiPath}/${id}`, { field, value });
  }

  static delete = id => {
    return deleteData(`${Distributors.apiPath}/${id}`);
  };
}

export default Distributors;
