import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { setTitle } from '../../lib/utils/windowutils';
import { connect } from 'react-redux';
import Loading from '../Common/Loading';
import { ltToast } from '../Common/LTToast';
import Octicon, { CloudUpload } from '@githubprimer/octicons-react';
import Functionalities from '../../lib/models/Functionalities';
import TagInput from '../Common/TagInput';

class FunctionalityCreateComp extends PureComponent {
  static propTypes = {
    sites: PropTypes.object.isRequired,
    modules: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    validated: false,
    title: '',
    description: '',
    site_id: '',
    tags: '',
    module: '',
    allTags: []
  };

  render() {
    const { sites, modules } = this.props;
    if (!sites.data || !modules.data) {
      return <Loading />;
    }
    const sitesIds = Object.keys(sites.data);

    const { title, description, site_id, tags, module, validated, allTags } = this.state;
    return (
      <div>
        <h5>Create new Functionality</h5>
        <form onSubmit={this.handleSubmit} className="mb-3 mt-3">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="card border-info">
                <div className="card-header bg-info text-white">
                  <strong>Detail</strong>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="functionalityInputTitle">Title</label>
                    <input
                      type="text"
                      className="form-control"
                      id="functionalityInputTitle"
                      name="title"
                      placeholder="Enter title"
                      autoComplete="off"
                      value={title}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="functionalityInputDescription">Description</label>
                    <textarea
                      rows={5}
                      className="form-control"
                      id="functionalityInputDescription"
                      name="description"
                      placeholder="Enter Description"
                      autoCorrect="off"
                      value={description}
                      onChange={this.handleChange}
                    />
                    <small>
                      Support Markdown:{' '}
                      <a
                        tabIndex={-1}
                        href="https://en.support.wordpress.com/markdown-quick-reference/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Help
                      </a>
                    </small>
                  </div>
                  <div className="form-group">
                    <label htmlFor="funtionalityInputSite">Module</label>
                    <select
                      className="form-control"
                      id="functionalityInputSite"
                      name="module"
                      onChange={this.handleChange}
                      value={module}
                    >
                      <option value="" disabled={true}>
                        Select module
                      </option>
                      {modules.data.map((module, i) => {
                        return (
                          <option value={module.code} key={i}>
                            {module.code}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="functionalityInputSite">First Site / Installation</label>
                    <select
                      className="form-control"
                      id="functionalityInputSite"
                      name="site_id"
                      onChange={this.handleChange}
                      value={site_id}
                    >
                      <option value="" disabled={true}>
                        Select site
                      </option>
                      {sitesIds.map((siteId, i) => {
                        const site = sites.data[siteId];
                        const showCustomer = site.description !== site.customer.description;
                        return (
                          <option key={i} value={site.id}>
                            [{site.distributor.code}]&nbsp;{site.description}
                            {showCustomer && <span>({site.customer.description})</span>}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="form-group">
                    <label>
                      Tags{' '}
                      <small>
                        <em>Add at least 2</em>
                      </small>
                    </label>
                    <TagInput tags={allTags} values={tags} onAddTag={this.addTag} />
                    <p className="my-1">
                      {tags.split(',').map((tag, i) => (
                        <span className="badge badge-primary mr-2" key={i} onClick={e => this.deleteTag(i)}>
                          {tag.trim()}
                        </span>
                      ))}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <button type="submit" className="btn btn-lg btn-info" disabled={!validated}>
                <Octicon icon={CloudUpload} size="medium" title="Save" />
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }

  componentDidMount() {
    setTitle('New Functionality');
    Functionalities.getTags()
      .then(tags => {
        this.setState({ allTags: tags });
      })
      .catch(e => {
        ltToast('Unable to load tags!', 3000, true);
      });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { title, description, site_id, tags, module } = this.state;
    let validated = false;
    if (title && description && site_id && module && tags.split(',').length > 1) {
      validated = true;
    }
    this.setState({ validated });
  }

  deleteTag = pos => {
    const { tags } = this.state;
    const nexttags = tags.split(',');
    nexttags.splice(pos, 1);
    this.setState({ tags: nexttags.join(',') });
  };

  addTag = tag => {
    let { tags } = this.state;
    if (tags !== '') {
      tags += ',';
    }
    tags += ' ' + tag;
    this.setState({ tags: tags });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { title, description, site_id, tags, module, validated } = this.state;
    if (!validated) {
      return;
    }
    const payload = {
      title,
      description,
      site_id,
      module,
      tags: tags.split(',').map(t => t.trim())
    };
    Functionalities.create(payload)
      .then(() => {
        this.props.history.push('./');
      })
      .catch(err => {
        ltToast('Unable to save: ' + err.message, 5000, true);
      });
    return false;
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
}

function mapStateToProps(state) {
  const { sites, modules } = state;

  return {
    sites,
    modules
  };
}

const FunctionalityCreate = connect(mapStateToProps)(FunctionalityCreateComp);
export default FunctionalityCreate;
