import React, { PureComponent } from 'react';
import { setTitle } from '../../lib/utils/windowutils';
import Modules from '../../lib/models/modules';
import PropTypes from 'prop-types';
import qs from 'qs';
import { ltToast } from '../Common/LTToast';
import Octicon, { CloudUpload, Dash, Link as IconLink, Plus, Search, Trashcan } from '@githubprimer/octicons-react';
import { handleOnKeyDown } from '../../lib/utils/commonutils';
import { Link } from 'react-router-dom';

class Module extends PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    validated: false,
    modules: { rows: [] },
    code: '',
    searchString: '',
    adding: false
  };

  render() {
    const { match, history } = this.props;
    const { modules, searchString, adding, validated, code } = this.state;
    const { offset } = this.getQueryParameters();
    return (
      <div>
        <div className="my-3 row">
          <div className="col-12 col-md-4 col-lg-3">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" onClick={this.handleSearch}>
                  <Octicon icon={Search} size="small" />
                </span>
              </div>
              <input
                type="text"
                value={searchString}
                onChange={e => this.setState({ searchString: e.target.value })}
                onKeyDown={e => handleOnKeyDown(e, this.handleSearch)}
                className="form-control form-control-sm"
                placeholder="Search"
              />
              <div className="input-group-append">
                <button
                  disabled={searchString === ''}
                  className="btn btn-sm btn-outline-danger"
                  onClick={e => history.push(match.url)}
                >
                  <Octicon icon={Trashcan} size="small" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <h5 className="mt-3">Modules</h5>

        {modules.rows.length > 0 && (
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th>Module</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {modules.rows.map((module, i) => {
                return (
                  <tr key={i} className="justify-content-between align-items-center">
                    <td>{module.code}</td>
                    <td className="text-right">
                      <Link className="btn btn-link" to={`/functionalities?q=module:${module.code}`}>
                        <Octicon icon={IconLink} size="small" />
                      </Link>
                      <button
                        className="btn btn-sm btn-outline-danger ml-3"
                        onClick={e => this.handleDelete(e, module.code)}
                      >
                        <Octicon icon={Trashcan} size="small" />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        {modules.rows.length === 0 && <div className="alert alert-info">No module found</div>}
        {adding && (
          <form onSubmit={this.handleSubmit} className="mb-3 mt-3">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                id="moduleInputCode"
                name="code"
                placeholder="Enter Module"
                autoComplete="off"
                value={code}
                onChange={this.handleChange}
              />
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <button type="submit" className="btn btn-lg btn-info" disabled={!validated}>
                  <Octicon icon={CloudUpload} size="small" title="Save" />
                </button>
              </div>
            </div>
          </form>
        )}
        <div className="d-flex bd-highlight mb-3">
          <div className="mt-3" title="Add Module">
            <button onClick={this.toggleModule} className="btn btn-info">
              <Octicon icon={adding ? Dash : Plus} size="small" />
            </button>
          </div>
          {(Number(offset) || modules.hasMore) && (
            <div className="mt-3 ml-auto p-2 bd-highlight">
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className={'page-item' + (!Number(this.getQueryParameters().offset) ? ' disabled' : '')}>
                    <Link to={this.getPaginationUrl('p')} className="page-link" onClick={e => e.target.blur()}>
                      Previous
                    </Link>
                  </li>
                  <li className={'page-item' + (modules.hasMore === false ? ' disabled' : '')}>
                    <Link to={this.getPaginationUrl('n')} className="page-link" onClick={e => e.target.blur()}>
                      Next
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          )}
        </div>
      </div>
    );
  }

  componentDidMount() {
    setTitle('Modules');
    this.loadModules();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.search !== this.props.location.search) {
      this.loadModules();
    }
    const { code } = this.state;
    let validated = false;
    if (code) {
      validated = true;
    }
    this.setState({ validated });
  }

  loadModules = () => {
    const { search } = this.props.location;
    const parameters = qs.parse(search, { ignoreQueryPrefix: true });
    const { limit, offset, order_by, sort, q } = parameters;

    let searchString = '';
    if (q) {
      searchString = q;
    }
    /*
        q.split(' ').forEach(search => {
          if (search.indexOf(':') < 0) {
            searchString += search;
          } else {

          }
        });
      }
       */
    if (this.state.searchString !== searchString) {
      this.setState({ searchString });
    }

    Modules.find({ limit, offset, order_by, sort, search: q }).then(modules => {
      this.setState({ modules });
    });
  };

  handleSearch = () => {
    const { pathname, search } = this.props.location;
    const parameters = qs.parse(search, { ignoreQueryPrefix: true });
    parameters.q = this.state.searchString;
    parameters.offset = 0;
    this.props.history.push(pathname + qs.stringify(parameters, { addQueryPrefix: true }));
  };

  handleDelete = (e, code) => {
    const ok = window.confirm('Are you sure?');
    e.target.blur();
    if (ok) {
      setTimeout(() => {
        Modules.delete(code)
          .then(() => {
            this.loadModules();
          })
          .catch(e => {
            ltToast('Unable to delete module: ' + e.message, 5000, true);
          }, 300);
      });
    }
  };
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { code, validated } = this.state;
    if (!validated) {
      return;
    }
    const payload = { code };
    Modules.create(payload)
      .then(() => {
        this.loadModules();
      })
      .then(() => {
        this.toggleModule();
      })
      .catch(err => {
        ltToast('Unable to save: ' + err.message, 5000, true);
      });
    return false;
  };

  toggleModule = () => {
    if (this.state.adding) {
      this.setState({ adding: false });
    } else {
      this.setState({ adding: true });
    }
  };

  getQueryParameters = () => {
    const { search } = this.props.location;
    return qs.parse(search, { ignoreQueryPrefix: true });
  };

  getPaginationUrl = direction => {
    const { pathname } = this.props.location;
    const parameters = this.getQueryParameters();
    let offset = Number(parameters.offset) || 0;
    let limit = Number(parameters.limit) || 10;
    let offsetPrev = offset - limit;
    let offsetNext = offset + limit;
    if (offsetPrev < 0) {
      offsetPrev = 0;
    }
    parameters.offset = direction === 'p' ? offsetPrev : offsetNext;
    return pathname + qs.stringify(parameters, { addQueryPrefix: true });
  };
}

export default Module;
