import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Octicon, { Clippy } from '@githubprimer/octicons-react';
import { ltToast } from '../Common/LTToast';
import Examples from '../../lib/models/Examples';
import Highlight from 'react-highlight.js';

class ExampleContentCard extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired,
    example: PropTypes.object.isRequired,
    onExampleChange: PropTypes.func.isRequired
  };

  state = {
    highlight: false,
    editField: '',
    editValue: '',
    editing: false
  };

  render() {
    const { example, currentContent } = this.props;
    const { editing, editField, editValue } = this.state;
    return (
      <div className="mb-3 col-12 col-md-6 col-lg-8">
        <div className="card border-info">
          <div className="card-header bg-info text-white">
            <strong>Content</strong>
            <div className="float-right">
              <div className="copy" title="Copy to clipboard">
                <button
                  type="button"
                  disabled={editing && editField === 'content'}
                  tabIndex={-1}
                  className="btn btn-link btn-sm"
                  onClick={this.copyToClipboard}
                >
                  <Octicon icon={Clippy} size="small" />
                </button>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div>
              {(!editing || editField !== 'content') && (
                <div>
                  <div style={{ maxHeight: 400, overflow: 'auto' }}>
                    <Highlight language="python">{example.content[currentContent].content}</Highlight>
                  </div>
                  <button
                    className="btn btn-info mr-2 btn-sm"
                    onClick={e =>
                      this.setState({
                        editField: 'content',
                        editValue: example.content[currentContent].content,
                        editing: true
                      })
                    }
                  >
                    EDIT
                  </button>
                </div>
              )}
              {editing && editField === 'content' && (
                <div>
                  <textarea
                    rows={20}
                    className="form-control"
                    placeholder="Enter/Paste Content"
                    autoCorrect="off"
                    value={editValue}
                    ref={this.refInput}
                    onChange={e => this.setState({ editValue: e.target.value })}
                  />
                  <div className="mt-3">
                    <button className="btn btn-info mr-2 btn-sm" onClick={this.saveContent}>
                      SAVE
                    </button>
                    <button className="btn btn-outline-warning mr-2 btn-sm" onClick={this.closeContent}>
                      CLOSE
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleChangeContent = () => {
    const { editValue } = this.state;
    console.log('got', editValue);
    if (!editValue) {
      return;
    }
    try {
      const j = JSON.parse(editValue);
      this.setState({
        editValue: JSON.stringify(j, null, 3)
      });
    } catch (e) {
      console.error(e);
      ltToast('Invalid content, not valid JSON!', 5000, true);
    }
  };

  closeContent = () => {
    this.setState({ editField: '', editValue: '', editing: false });
    this.refInput = React.createRef();
  };

  saveContent = () => {
    const { example, onExampleChange } = this.props;
    const { editValue } = this.state;
    this.setState({ editField: '', editValue: '', editing: false });
    this.refInput = React.createRef();
    if (example.content[0].content !== editValue) {
      Examples.updateContent(example.id, editValue)
        .then(example => {
          onExampleChange();
        })
        .catch(e => {
          ltToast('Unable to update content: ' + e.message, 5000, true);
        });
    }
  };


}

export default ExampleContentCard;
