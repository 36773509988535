import { RECEIVE_CATEGORIES, REQUEST_CATEGORIES, FAILED_CATEGORIES } from './categoriesActions';

const initialState = {
  isFetching: false,
  data: undefined
};

export default function categoriesReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_CATEGORIES:
      return Object.assign({}, state, { isFetching: true });

    case RECEIVE_CATEGORIES:
      return Object.assign({}, state, { isFetching: false, data: action.data });

    case FAILED_CATEGORIES:
      return Object.assign({}, state, { isFetching: false, data: false });

    default:
      return state;
  }
}
