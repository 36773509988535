import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Loading from '../Common/Loading';
import DescriptionEdit from '../Common/DescriptionEdit';
import { handleOnKeyDown } from '../../lib/utils/commonutils';
import ReactMarkdown from 'react-markdown';
import { setTitle } from '../../lib/utils/windowutils';
import Customers from '../../lib/models/Customers';
import { ltToast } from '../Common/LTToast';
import { Link } from 'react-router-dom';
import Distributors from '../../lib/models/Distributors';
import Octicon, { Link as IconLink, Plus } from '@githubprimer/octicons-react';
import Sites from '../../lib/models/sites/sites';

class CustomerView extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  state = {
    customer: false,
    distributors: { rows: [] },
    sites: { rows: [] },
    editField: '',
    editValue: '',
    editing: false
  };

  refInput = React.createRef();

  render() {
    const { customer, distributors, sites, editing, editField, editValue } = this.state;
    if (!customer) {
      return <Loading />;
    }

    return (
      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/admin/">Admin</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/customers">Customers</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Edit
            </li>
          </ol>
        </nav>
        <h5 className="mb-3">Edit Customer</h5>
        <div className="row">
          <div className="mb-3 col-12 col-md-6 col-lg-4">
            <div className="card border-info">
              <div className="card-header bg-info text-white">
                <strong>Detail</strong>
              </div>
              <div className="card-body">
                <dl className="row mb-0">
                  <dt className="col-12 col-md-4 col-lg-3">Code</dt>
                  <dd
                    className="col-12 col-md-8 col-lg-9"
                    onClick={e => this.setState({ editField: 'code', editValue: customer.code, editing: true })}
                  >
                    {(!editing || editField !== 'code') && <DescriptionEdit>{customer.code}</DescriptionEdit>}
                    {editing && editField === 'code' && (
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        value={editValue}
                        ref={this.refInput}
                        onBlur={this.saveEdit}
                        onKeyDown={e => handleOnKeyDown(e, this.saveEdit)}
                        onChange={e => this.setState({ editValue: e.target.value })}
                      />
                    )}
                  </dd>
                  <dt className="col-12 col-md-4 col-lg-3">Description</dt>
                  <dd
                    className="col-12 col-md-8 col-lg-9"
                    onClick={e =>
                      this.setState({ editField: 'description', editValue: customer.description, editing: true })
                    }
                  >
                    {(!editing || editField !== 'description') && (
                      <DescriptionEdit>
                        <ReactMarkdown source={customer.description} />
                      </DescriptionEdit>
                    )}
                    {editing && editField === 'description' && (
                      <div>
                        <span>
                          Use Markdown:{' '}
                          <a
                            href="https://en.support.wordpress.com/markdown-quick-reference/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Help
                          </a>
                        </span>
                        <textarea
                          className="form-control form-control-sm"
                          value={editValue}
                          autoCorrect="off"
                          rows={5}
                          ref={this.refInput}
                          onBlur={this.saveEdit}
                          onClick={e =>
                            this.setState({
                              editField: 'description',
                              editValue: customer.description,
                              editing: true
                            })
                          }
                          onChange={e => this.setState({ editValue: e.target.value })}
                        />
                      </div>
                    )}
                  </dd>
                  <dt className="col-12 col-md-4 col-lg-3">Distributor</dt>
                  <dd
                    className="col-12 col-md-8 col-lg-9"
                    onClick={e =>
                      this.setState({ editField: 'distributor_id', editValue: customer.distributor_id, editing: true })
                    }
                  >
                    {(!editing || editField !== 'distributor_id') && (
                      <DescriptionEdit>
                        [{customer.distributor_code}]&nbsp;{customer.distributor_description}
                      </DescriptionEdit>
                    )}
                    {editing && editField === 'distributor_id' && (
                      <select
                        className="form-control"
                        onChange={e => this.setState({ editValue: e.target.value })}
                        onBlur={this.saveEdit}
                        value={editValue}
                      >
                        {distributors.rows.map((distributor, i) => {
                          return (
                            <option key={i} value={distributor.id}>
                              [{distributor.code}]&nbsp;{distributor.description}
                            </option>
                          );
                        })}
                      </select>
                    )}
                    <div className="mt-3">
                      <Link
                        className="btn btn-info"
                        to={`/admin/distributors/${customer.distributor_id}`}
                        title="Edit Customer"
                      >
                        <Octicon icon={IconLink} size="small" />
                      </Link>
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div className="mb-3 col-12 col-md-6 col-lg-4">
            <div className="card border-info">
              <div className="card-header bg-info text-white">
                <strong>Sites</strong>
              </div>
              <div className="card-body">
                <div className="list-group">
                  {sites.rows.map((site, i) => {
                    return (
                      <div key={i} className="list-group-item d-flex justify-content-between align-items-center">
                        {site.code} {site.description}
                        <Link className="btn btn-link" to={`/admin/sites/${site.id}`} title="Edit Site">
                          <Octicon icon={IconLink} size="small" />
                        </Link>
                      </div>
                    );
                  })}
                  {sites.rows.length === 0 && (
                    <div className="list-group-item">
                      <em>No Sites found.</em>
                    </div>
                  )}
                </div>
                <div className="mt-3">
                  <Link
                    className="btn btn-info"
                    to={`/admin/sites/create?customer_id=${customer.id}`}
                    title="Add new site"
                  >
                    <Octicon icon={Plus} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="mb-3 col-12 col-md-6 col-lg-4">
            <div className="card border-danger">
              <div className="card-header bg-danger text-white">
                <strong>DANGER ZONE</strong>
              </div>
              <div className="card-body">
                <button className="btn btn-danger" onClick={this.handleDelete}>
                  DELETE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.loadCustomer();
    Distributors.find({ limit: 100 }).then(distributors => {
      this.setState({ distributors });
    });
    this.loadSites();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.customer && this.state.customer.id && !prevState.customer.id) {
      setTitle(this.state.customer.code);
    }
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.loadCustomer();
    }
    if (!prevState.editing && this.state.editing && this.refInput.current) {
      this.refInput.current.focus();
    }
  }

  handleChange = e => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value
    });
    this.setState({ editField: e.target.name, editValue: e.target.value });
  };

  handleDelete = e => {
    e.preventDefault();
    const ok = window.confirm('Are you sure?');
    if (ok) {
      const { customer } = this.state;
      Customers.delete(customer.id)
        .then(() => {
          this.props.history.push('/admin/customers');
        })
        .catch(e => {
          ltToast('Unable to delete entity: ' + e.message, 5000, true);
        });
    }
  };

  saveEdit = () => {
    const { editField, editValue, customer } = this.state;
    this.setState({ editField: '', editValue: '', editing: false });
    this.refInput = React.createRef();
    if (customer[editField] !== editValue) {
      Customers.update(customer.id, editField, editValue)
        .then(customer => {
          this.setState({ customer });
        })
        .catch(e => {
          ltToast('Unable to save: ' + e.message, 5000, true);
        });
    }
  };

  loadCustomer = () => {
    const { match } = this.props;
    const { params } = match;
    Customers.get(params.id)
      .then(customer => {
        this.setState({
          customer
        });
      })
      .catch(e => console.error('err', e.message));
  };

  loadSites = () => {
    const { match } = this.props;
    const { params } = match;
    Sites.find({ search: `customer_id:${params.id}` }).then(sites => this.setState({ sites }));
  };
}

export default CustomerView;
