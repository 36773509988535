import Sites from './sites';

export const REQUEST_SITES = 'REQUEST_SITES';
export const RECEIVE_SITES = 'RECEIVE_SITES';
export const FAILED_SITES = 'FAILED_SITES';

export function doGetSites() {
  return dispatch => {
    dispatch(requestSites());
    return Sites.getSites()
      .then(json => {
        dispatch(reciveSites(json));
      })
      .catch(error => dispatch(failedFetchSites(error.message)));
  };
}

function requestSites() {
  return {
    type: REQUEST_SITES
  };
}

function reciveSites(json) {
  return {
    type: RECEIVE_SITES,
    data: json
  };
}

function failedFetchSites(error) {
  console.log(error);
  return {
    type: FAILED_SITES
  };
}

