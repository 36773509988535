import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { setTitle } from '../../lib/utils/windowutils';

class AdminMain extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    setTitle('Admin');
  }

  render() {
    return (
      <div className="mt-3">
        <div className="row mb-3">
          <div className="col-md-6">
            <div className="card border-info">
              <div className="card-header bg-info text-white">
                <strong>Distributors</strong>
              </div>
              <div className="card-body">
                <div className="list-group">
                  <Link to="./distributors" className="list-group-item list-group-item-action">
                    Distributors
                  </Link>
                  <Link to="./customers" className="list-group-item list-group-item-action">
                    Customers
                  </Link>
                  <Link to="./sites" className="list-group-item list-group-item-action">
                    Sites
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card border-info">
              <div className="card-header bg-info text-white">
                <strong>Functionalities / Entities settings</strong>
              </div>
              <div className="card-body">
                <div className="list-group">

                  <Link to="./modules" className="list-group-item list-group-item-action">
                    Modules
                  </Link>
                  <Link to="./categories" className="list-group-item list-group-item-action">
                    Categories
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <div className="card border-info">
              <div className="card-header bg-info text-white">
                <strong>Auth</strong>
              </div>
              <div className="card-body">
                <div className="list-group">
                  <Link to="./domains" className="list-group-item list-group-item-action">
                    Domains
                  </Link>
                  <Link to="./accounts" className="list-group-item list-group-item-action">
                    Accounts
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminMain;
