import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Loading from '../Common/Loading';
import DescriptionEdit from '../Common/DescriptionEdit';
import { setTitle } from '../../lib/utils/windowutils';
import Accounts from '../../lib/models/Accounts';
import { ltToast } from '../Common/LTToast';
import { Link } from 'react-router-dom';
import Octicon, { Trashcan, Plus } from '@githubprimer/octicons-react';
import { getRoles, getRolesAsString, loadRoles } from '../../lib/models/Roles';
import { connect } from 'react-redux';
import { handleOnKeyDown } from '../../lib/utils/commonutils';

class AccountView extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired
  };

  state = {
    account: false,
    editField: '',
    editValue: '',
    editing: false,
    error: false
  };

  refInput = React.createRef();

  render() {
    const { account, editing, editField, editValue, error } = this.state;
    return (
      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/admin/">Admin</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/accounts">Accounts</Link>
            </li>
            {account && (
              <li className="breadcrumb-item active" aria-current="page">
                {account.email}
              </li>
            )}
          </ol>
        </nav>
        {error && <div className="alert alert-danger">{error}</div>}
        {!error && !account && <Loading />}
        {account && (
          <div>
            <h5 className="mb-3">Edit Account</h5>
            <div className="row">
              <div className="mb-3 col-12 col-md-6 col-lg-4">
                <div className="card border-info">
                  <div className="card-header bg-info text-white">
                    <strong>Detail</strong>
                  </div>
                  <div className="card-body">
                    <dl className="row">
                      <dt className="col-12 col-md-4 col-lg-3">Email</dt>
                      <dd className="col-12 col-md-8 col-lg-9">{account.email}</dd>
                      <dt className="col-12 col-md-4 col-lg-3">Name</dt>
                      <dd className="col-12 col-md-8 col-lg-9">
                        {(!editing || editField !== 'name') && (
                          <div
                            onClick={() => {
                              this.setState({
                                editField: 'name',
                                editValue: account.name,
                                editing: true
                              });
                            }}
                          >
                            <DescriptionEdit>{account.name}</DescriptionEdit>
                          </div>
                        )}
                        {editing && editField === 'name' && (
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            value={editValue}
                            ref={this.refInput}
                            onBlur={this.saveEdit}
                            onKeyDown={e => handleOnKeyDown(e, this.saveEdit)}
                            onChange={e => this.setState({ editValue: e.target.value })}
                          />
                        )}
                      </dd>
                      <dt className="col-12 col-md-4 col-lg-3">Roles</dt>
                      <dd className="col-12 col-md-8 col-lg-9">
                        {(!editing || editField !== 'role') && (
                          <div
                            onClick={() => {
                              const roles = getRoles(account.role).map(role => role.id);
                              this.setState({
                                editField: 'role',
                                editValue: roles,
                                editing: true
                              });
                            }}
                          >
                            <DescriptionEdit>{getRolesAsString(account.role)}</DescriptionEdit>
                          </div>
                        )}
                        {editing && editField === 'role' && (
                          <select
                            multiple={true}
                            className="form-control form-control-sm"
                            onChange={this.onEditRoles}
                            onBlur={this.saveEdit}
                            value={editValue}
                          >
                            {this.roles.map((role, i) => {
                              return (
                                <option key={i} value={role.id}>
                                  {role.name}
                                </option>
                              );
                            })}
                          </select>
                        )}
                      </dd>
                      <dt className="col-12 col-md-4 col-lg-3">Status</dt>
                      <dd
                        className="col-12 col-md-8 col-lg-9"
                        onClick={e => this.setState({ editField: 'status', editValue: account.status, editing: true })}
                      >
                        {(!editing || editField !== 'status') && (
                          <DescriptionEdit>{account.status ? 'Enabled' : 'Disabled'}</DescriptionEdit>
                        )}
                        {editing && editField === 'status' && (
                          <select
                            className="form-control form-control-sm"
                            onChange={e => this.setState({ editValue: e.target.value })}
                            onBlur={this.saveEdit}
                            value={editValue}
                          >
                            <option value={0}>Disable</option>
                            <option value={1}>Enable</option>
                          </select>
                        )}
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>

              <div className="mb-3 col-12 col-md-6 col-lg-8">
                <div className="card border-info">
                  <div className="card-header bg-info text-white">
                    <strong>TOKENS</strong>
                  </div>
                  <div className="card-body">
                    <div className="list-group mb-3">
                      {account.tokens.map((token, i) => {
                        const created_at = new Date(token.created_at);
                        return (
                          <div key={i} className="list-group-item">
                            <button
                              type="button"
                              className="btn btn-link float-right"
                              onClick={e => this.handleDeleteToken(token)}
                            >
                              <Octicon icon={Trashcan} size="small" />
                            </button>
                            <div>{token.id}</div>
                            <small>
                              Added by {token.created_by}, {created_at.toLocaleDateString('it-IT')}{' '}
                              {created_at.toLocaleTimeString('it-IT')}
                            </small>
                          </div>
                        );
                      })}
                    </div>
                    <button className="btn btn-info" onClick={this.createToken}>
                      <Octicon icon={Plus} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  componentDidMount() {
    loadRoles().then(roles => {
      this.roles = roles;
      this.loadAccount();
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.account && this.state.account.id && !prevState.account.id) {
      setTitle(this.state.account.email);
    }
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.loadAccount();
    }
    if (!prevState.editing && this.state.editing && this.refInput.current) {
      this.refInput.current.focus();
    }
  }

  onEditRoles = e => {
    const options = e.target.options;
    const selectedOptions = [];

    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedOptions.push(options[i].value);
      }
    }
    this.setState({ editValue: selectedOptions });
  };

  onEditLangs = e => {
    const options = e.target.options;
    const selectedOptions = [];

    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedOptions.push(options[i].value);
      }
    }
    this.setState({ editValue: selectedOptions });
  };

  handleDeleteToken = token => {
    const { account } = this.state;
    Accounts.deleteToken(account.id, token.id)
      .then(account => {
        this.setState({ account });
      })
      .catch(e => {
        ltToast('Unable to delete token: ' + e.message, 3000, true);
      });
  };

  createToken = e => {
    e.preventDefault();
    const { account } = this.state;
    Accounts.createToken(account.id)
      .then(account => {
        this.setState({ account });
      })
      .catch(err => {
        ltToast('Unable to create token: ' + err.message, 5000, true);
      });
  };

  handleChange = e => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value
    });
    this.setState({ editField: e.target.name, editValue: e.target.value });
  };

  saveEdit = () => {
    const { editField, editValue, account } = this.state;
    this.setState({ editField: '', editValue: '', editing: false });
    this.refInput = React.createRef();
    if (account[editField] !== editValue) {
      Accounts.update(account.id, editField, editValue)
        .then(account => {
          this.setState({ account });
        })
        .catch(e => {
          ltToast('Unable to save: ' + e.message, 5000, true);
        });
    }
  };

  loadAccount = () => {
    const { match } = this.props;
    const { params } = match;
    Accounts.get(params.id)
      .then(account => {
        this.setState({
          account
        });
      })
      .catch(e => {
        this.setState({ error: e.message });
      });
  };
}

function mapStateToProps(state) {
  const { user, settings } = state;

  return {
    user,
    settings
  };
}

export default connect(mapStateToProps)(AccountView);
