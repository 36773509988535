import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Loading from './components/Common/Loading';
import { connect, Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { history } from './store';
import TopMenu from './components/TopMenu';
import Login from './components/Login';
import Main from './components/Main';
import { setTitle } from './lib/utils/windowutils';
import { doGetConfig } from './lib/models/settings';
import { doGetUserMe } from './lib/models/user';
import EntityRoot from './components/Entity';
import FunctionalityRoot from './components/Functionality';
import ExampleRoot from './components/Example';
import AdminRoot from './components/Admin';

class BaseAppComp extends PureComponent {
  static propTypes = {
    settings: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    store: PropTypes.object.isRequired
  };

  render() {
    const { settings, store } = this.props;
    const { data } = settings;
    if (data === undefined) {
      return <Loading />;
    }
    if (data === false) {
      return (
        <div className="jumbotron">
          <div className="container">
            <h1>
              Failed to contact backend.
              <br />
              Call 911
            </h1>
          </div>
        </div>
      );
    }
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <div className="height-100p100">
            <Router>
              <div className="root-wrapper">
                <div className="header-wrapper">
                  <TopMenu />
                </div>
                <div className="container-fluid main-wrapper">
                  <Switch>
                    <Route exact path="/login" component={Login} />
                    <Route path="/functionalities" component={FunctionalityRoot} />
                    <Route path="/entities" component={EntityRoot} />
                    <Route path="/examples" component={ExampleRoot} />
                    <Route exact path="/" component={Main} />
                    <Route path="/admin" component={AdminRoot} />
                  </Switch>
                </div>
              </div>
            </Router>
          </div>
        </ConnectedRouter>
      </Provider>
    );
  }

  componentDidMount() {
    setTitle('Home');
    this.props.dispatch(doGetConfig());
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.settings.data && this.props.settings.data) {
      if (!this.props.user.isLoaded) {
        this.props.dispatch(doGetUserMe());
      }
    }
  }
}

function mapStateToProps(state) {
  const { settings, user } = state;

  return {
    settings,
    user
  };
}

const App = connect(mapStateToProps)(BaseAppComp);
export default App;
