import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { setTitle } from '../../lib/utils/windowutils';
import Functionalities from '../../lib/models/Functionalities';
import Loading from '../Common/Loading';
import ReactMarkdown from 'react-markdown';
import { getFormattedStringDate } from '../../lib/utils/dateUtils';
import { handleOnKeyDown } from '../../lib/utils/commonutils';
import { ltToast } from '../Common/LTToast';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SitesCard from '../Common/SitesCard';
import DescriptionEdit from '../Common/DescriptionEdit';
import FunctionalityEntitiesCard from './FunctionalityEntitiesCard';

class FunctionalityView extends PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    sites: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
  };

  fileInputRef = React.createRef();

  state = {
    functionality: false,
    editField: '',
    editValue: '',
    editing: false
  };

  refInput = React.createRef();

  render() {
    const { sites, user } = this.props;
    const { functionality, editing, editField, editValue } = this.state;
    if (!functionality) {
      return <Loading />;
    }

    return (
      <div>
        <div className="row">
          <div className="mb-3 col-12 col-md-6 col-lg-4">
            <div className="card border-info">
              <div className="card-header bg-info text-white">
                <strong>Detail</strong>
              </div>
              <div className="card-body">
                <dl className="row">
                  <dt className="col-12 col-md-4 col-lg-3">Title</dt>
                  <dd
                    className="col-12 col-md-8 col-lg-9"
                    onClick={e => this.setState({ editField: 'title', editValue: functionality.title, editing: true })}
                  >
                    {(!editing || editField !== 'title') && <DescriptionEdit>{functionality.title}</DescriptionEdit>}
                    {editing && editField === 'title' && (
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        value={editValue}
                        ref={this.refInput}
                        onBlur={this.saveEdit}
                        onKeyDown={e => handleOnKeyDown(e, this.saveEdit)}
                        onChange={e => this.setState({ editValue: e.target.value })}
                      />
                    )}
                  </dd>
                  <dt className="col-12 col-md-4 col-lg-3">Description</dt>

                  <dd
                    className="col-12 col-md-8 col-lg-9"
                    onClick={e =>
                      this.setState({ editField: 'description', editValue: functionality.description, editing: true })
                    }
                  >
                    {(!editing || editField !== 'description') && (
                      <DescriptionEdit>
                        <ReactMarkdown source={functionality.description} />
                      </DescriptionEdit>
                    )}
                    {editing && editField === 'description' && (
                      <div>
                        <span>
                          Use Markdown:{' '}
                          <a
                            href="https://en.support.wordpress.com/markdown-quick-reference/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Help
                          </a>
                        </span>
                        <textarea
                          className="form-control form-control-sm"
                          value={editValue}
                          autoCorrect="off"
                          rows={5}
                          ref={this.refInput}
                          onBlur={this.saveEdit}
                          onChange={e => this.setState({ editValue: e.target.value })}
                        />
                      </div>
                    )}
                  </dd>
                  <dt className="col-12 col-md-4 col-lg-3">Module</dt>
                  <dd className="col-12 col-md-8 col-lg-9">{functionality.module}</dd>
                  <dt className="col-12 col-md-4 col-lg-3">Tags</dt>
                  <dd
                    className="col-12 col-md-8 col-lg-9"
                    onClick={e =>
                      this.setState({
                        editField: 'tags',
                        editValue: functionality.tags.map(tag => tag).join(','),
                        editing: true
                      })
                    }
                  >
                    {(!editing || editField !== 'tags') && (
                      <DescriptionEdit>
                        {functionality.tags.map((tag, i) => {
                          return (
                            <Link to={`./?q=tag:${tag}`} key={i}>
                              <span key={i} className="badge badge-primary mr-2">
                                {tag}
                              </span>
                            </Link>
                          );
                        })}
                      </DescriptionEdit>
                    )}
                    {editing && editField === 'tags' && (
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        value={editValue}
                        ref={this.refInput}
                        onBlur={this.saveTags}
                        onKeyDown={e => handleOnKeyDown(e, this.saveTags)}
                        onChange={e => this.setState({ editValue: e.target.value })}
                      />
                    )}
                  </dd>
                  <dt className="col-12 col-md-4 col-lg-3">Created by</dt>
                  <dd className="col-12 col-md-8 col-lg-9">
                    {functionality.created_by} on {getFormattedStringDate(functionality.created_at)}
                  </dd>
                  {functionality.updated_at && <dt className="col-12 col-md-4 col-lg-3">Updated by</dt>}
                  {functionality.updated_at && (
                    <dd className="col-12 col-md-8 col-lg-9">
                      {functionality.updated_by} on {getFormattedStringDate(functionality.updated_at)}
                    </dd>
                  )}
                </dl>
              </div>
            </div>
          </div>
          <div className="mb-3 col-12 col-md-6 col-lg-4">
            <SitesCard
              obj={functionality}
              clazz={Functionalities}
              sites={sites}
              onObjChange={functionality => this.setState({ functionality })}
            />
          </div>
          {user.data.sl_admin && (
            <div className="mb-3 col-12 col-md-6 col-lg-4">
              <div className="card border-danger">
                <div className="card-header bg-danger text-white">
                  <strong>DANGER ZONE</strong>
                </div>
                <div className="card-body">
                  <button className="btn btn-danger" onClick={this.handleDelete}>
                    DELETE
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="row">
          <div className="mb-3 col-12 col-lg-8">
            <FunctionalityEntitiesCard
              functionality={functionality}
              onFunctionalityChange={functionality => this.setState({ functionality })}
            />
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.loadFunctionality();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.functionality && this.state.functionality.id && !prevState.functionality.id) {
      setTitle(this.state.functionality.title);
    }
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.loadFunctionality();
    }
    if (!prevState.editing && this.state.editing && this.refInput.current) {
      this.refInput.current.focus();
    }
  }

  handleDelete = e => {
    e.preventDefault();
    const ok = window.confirm('Are you sure?');
    if (ok) {
      const { functionality } = this.state;
      Functionalities.delete(functionality.id)
        .then(() => {
          this.props.history.push('/functionalities');
        })
        .catch(e => {
          ltToast('Unable to delete functionality: ' + e.message, 5000, true);
        });
    }
  };

  saveTags = () => {
    const { editValue, functionality } = this.state;
    this.setState({ editField: '', editValue: '', editing: false });
    this.refInput = React.createRef();
    if (functionality.tags.map(tag => tag).join(',') !== editValue) {
      Functionalities.updateTags(functionality.id, editValue.split(','))
        .then(functionality => {
          this.setState({ functionality });
        })
        .catch(e => {
          ltToast('Unable to update tags: ' + e.message, 5000, true);
        });
    }
  };

  saveEdit = () => {
    const { editField, editValue, functionality } = this.state;
    this.setState({ editField: '', editValue: '', editing: false });
    this.refInput = React.createRef();
    if (functionality[editField] !== editValue) {
      Functionalities.update(functionality.id, editField, editValue)
        .then(functionality => {
          this.setState({ functionality });
        })
        .catch(e => {
          ltToast('Unable to save: ' + e.message, 5000, true);
        });
    }
  };

  loadFunctionality = () => {
    const { match } = this.props;
    const { params } = match;
    Functionalities.get(params.id)
      .then(functionality => {
        this.setState({
          functionality
        });
      })
      .catch(e => console.error('err', e.message));
  };
}

function mapStateToProps(state) {
  const { sites, user } = state;

  return {
    sites,
    user
  };
}

export default connect(mapStateToProps)(FunctionalityView);
