import { RECEIVE_MODULES, REQUEST_MODULES, FAILED_MODULES } from './modulesActions';

const initialState = {
  isFetching: false,
  data: undefined
};

export default function modulesReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_MODULES:
      return Object.assign({}, state, { isFetching: true });

    case RECEIVE_MODULES:
      return Object.assign({}, state, { isFetching: false, data: action.data });

    case FAILED_MODULES:
      return Object.assign({}, state, { isFetching: false, data: false });

    default:
      return state;
  }
}
