import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { setTitle } from '../../lib/utils/windowutils';
import Customers from '../../lib/models/Customers';
import { ltToast } from '../Common/LTToast';
import Octicon, { CloudUpload } from '@githubprimer/octicons-react';
import Distributors from '../../lib/models/Distributors';
import qs from 'qs';
import { Link } from 'react-router-dom';

class CustomerCreate extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired
  };

  state = {
    validated: false,
    code: '',
    description: '',
    distributor_id: '',
    distributors: { rows: [] }
  };

  render() {
    const { code, description, distributors, distributor_id, validated } = this.state;
    return (
      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/admin/">Admin</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/customers">Customers</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Create
            </li>
          </ol>
        </nav>
        <h5 className="mb-3">Create new Customer</h5>
        <form onSubmit={this.handleSubmit} className="mb-3 mt-3">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-5">
              <div className="card border-info">
                <div className="card-header bg-info text-white">
                  <strong>Detail</strong>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="customerInputCode">Code</label>
                    <input
                      type="text"
                      className="form-control"
                      id="customerInputCode"
                      name="code"
                      placeholder="Enter code"
                      autoComplete="off"
                      value={code}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="customerInputDescription">Description</label>
                    <textarea
                      rows={5}
                      className="form-control"
                      id="customerInputDescription"
                      name="description"
                      placeholder="Enter Description"
                      autoCorrect="off"
                      value={description}
                      onChange={this.handleChange}
                    />
                    <small>
                      Support Markdown:{' '}
                      <a
                        tabIndex={-1}
                        href="https://en.support.wordpress.com/markdown-quick-reference/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Help
                      </a>
                    </small>
                  </div>
                  <div className="form-group">
                    <label htmlFor="customerInputDistributor">Distributor</label>
                    <select
                      className="form-control"
                      id="customerInputDistributor"
                      name="distributor_id"
                      onChange={this.handleChange}
                      value={distributor_id}
                    >
                      <option />
                      {distributors.rows.map((distributor, i) => {
                        return (
                          <option key={i} value={distributor.id}>
                            [{distributor.code}]&nbsp;{distributor.description}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <button type="submit" className="btn btn-lg btn-info" disabled={!validated}>
                <Octicon icon={CloudUpload} size="small" title="Save" />
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }

  componentDidMount() {
    setTitle('New Customer');
    Distributors.find({ limit: 100 }).then(distributors => {
      let distributor_id = this.getDistributorId();
      console.log('distributor_id', distributor_id);
      if (!distributor_id) {
        distributor_id = '';
      }
      this.setState({ distributors, distributor_id });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { code, description, distributor_id } = this.state;
    let validated = false;
    if (code && description && distributor_id) {
      validated = true;
    }
    this.setState({ validated });
  }

  getDistributorId = () => {
    const queries = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    return queries.distributor_id;
  };

  handleSubmit = e => {
    e.preventDefault();
    const { code, description, distributor_id, validated } = this.state;
    if (!validated) {
      return;
    }
    const payload = {
      code,
      description,
      distributor_id
    };
    Customers.create(payload)
      .then(() => {
        const distributor_id = this.getDistributorId();
        if (distributor_id) {
          this.props.history.push('../distributors/' + distributor_id);
          return;
        }
        this.props.history.push('./');
      })
      .catch(err => {
        ltToast('Unable to save: ' + err.message, 5000, true);
      });
    return false;
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
}

export default CustomerCreate;
