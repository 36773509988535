import Modules from './modules';

export const REQUEST_MODULES = 'REQUEST_MODULES';
export const RECEIVE_MODULES = 'RECEIVE_MODULES';
export const FAILED_MODULES = 'FAILED_MODULES';

export function doGetModules() {
  return dispatch => {
    dispatch(requestModules());
    return Modules.getModules()
      .then(json => {
        dispatch(reciveModules(json));
      })
      .catch(error => dispatch(failedFetchModules(error.message)));
  };
}

function requestModules() {
  return {
    type: REQUEST_MODULES
  };
}

function reciveModules(json) {
  return {
    type: RECEIVE_MODULES,
    data: json
  };
}

function failedFetchModules(error) {
  console.log(error);
  return {
    type: FAILED_MODULES
  };
}

