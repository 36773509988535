import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import SiteCreate from './SiteCreate';
import SiteMain from './SiteMain';
import SiteView from './SiteView';

class SiteRoot extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  render() {
    const { match } = this.props;
    return (
      <div className="entity-wrapper mt-3">
        <Switch>
          <Route exact path={match.path} component={SiteMain} />
          <Route exact path={`${match.path}/create`} component={SiteCreate} />
          <Route exact path={`${match.path}/:id`} component={SiteView} />
        </Switch>
      </div>
    );
  }
}

export default SiteRoot;
