import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import CustomerCreate from './CustomerCreate';
import CustomerMain from './CustomerMain';
import CustomerView from './CustomerView';

class CustomerRoot extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  render() {
    const { match } = this.props;
    return (
      <div className="entity-wrapper mt-3">
        <Switch>
          <Route exact path={match.path} component={CustomerMain} />
          <Route exact path={`${match.path}/create`} component={CustomerCreate} />
          <Route exact path={`${match.path}/:id`} component={CustomerView} />
        </Switch>
      </div>
    );
  }
}

export default CustomerRoot;
