import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { setTitle } from '../../lib/utils/windowutils';
import Distributors from '../../lib/models/Distributors';
import { ltToast } from '../Common/LTToast';
import Octicon, { CloudUpload } from '@githubprimer/octicons-react';
import { Link } from 'react-router-dom';

class DistributorCreate extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired
  };

  state = {
    validated: false,
    code: '',
    description: ''
  };

  render() {
    const { code, description, validated } = this.state;
    return (
      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/admin/">Admin</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/distributors">Distributors</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Create
            </li>
          </ol>
        </nav>
        <h5 className="mb-3">Create new Distributor</h5>
        <form onSubmit={this.handleSubmit} className="mb-3 mt-3">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-5">
              <div className="card border-info">
                <div className="card-header bg-info text-white">
                  <strong>Detail</strong>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="distributorInputTitle">Code</label>
                    <input
                      type="text"
                      className="form-control"
                      id="distributorInputCode"
                      name="code"
                      placeholder="Enter code"
                      autoComplete="off"
                      value={code}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="distributorInputDescription">Description</label>
                    <textarea
                      rows={5}
                      className="form-control"
                      id="distributorInputDescription"
                      name="description"
                      placeholder="Enter Description"
                      autoCorrect="off"
                      value={description}
                      onChange={this.handleChange}
                    />
                    <small>
                      Support Markdown:{' '}
                      <a
                        tabIndex={-1}
                        href="https://en.support.wordpress.com/markdown-quick-reference/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Help
                      </a>
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <button type="submit" className="btn btn-lg btn-info" disabled={!validated}>
                <Octicon icon={CloudUpload} size="small" title="Save" />
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }

  componentDidMount() {
    setTitle('New Distributor');
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { code, description } = this.state;
    let validated = false;
    if (code && description) {
      validated = true;
    }
    this.setState({ validated });
  }

  handleSubmit = e => {
    e.preventDefault();
    const { code, description, validated } = this.state;
    if (!validated) {
      return;
    }
    const payload = {
      code,
      description
    };
    Distributors.create(payload)
      .then(() => {
        this.props.history.push('./');
      })
      .catch(err => {
        ltToast('Unable to save: ' + err.message, 5000, true);
      });
    return false;
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
}

export default DistributorCreate;
