import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getFormattedStringDate } from '../../lib/utils/dateUtils';
import Octicon, { CloudDownload, CloudUpload, Trashcan, Info } from '@githubprimer/octicons-react';
import { ltToast } from '../Common/LTToast';
import Entities from '../../lib/models/Entities';
import download from 'downloadjs';
import slugify from 'slugify';

class EntityContentCard extends PureComponent {
  static propTypes = {
    entity: PropTypes.object.isRequired,
    onEntityChange: PropTypes.func.isRequired
  };

  state = {
    highlight: false,
    file: null,
    content: null,
    message: ''
  };

  inputMessageRef = React.createRef();
  fileInputRef = React.createRef();

  render() {
    const { entity } = this.props;
    const { file, message } = this.state;
    return (
      <div className="card border-info">
        <div className="card-header bg-info text-white">
          <strong>Content</strong>
        </div>
        <div className="card-body">
          <table className="table table-hover table-striped table-sm">
            <thead>
              <tr>
                <th>Uploaded by</th>
                <th>Date</th>
                <th />
                <th />
                <th />
              </tr>
            </thead>
            <tbody>
              {entity.content.map((content, i) => {
                return (
                  <tr key={i} className="align-text-bottom">
                    <td>{content.created_by}</td>
                    <td>
                      <em>{getFormattedStringDate(content.created_at)}</em>
                    </td>
                    <td>
                      <a
                        href={`${Entities.apiPath}/${entity.id}/content/${content.id}`}
                        className="btn btn-link"
                        onClick={e => this.downloadContent(e, content.id)}
                      >
                        <Octicon icon={CloudDownload} size="small" />
                      </a>
                    </td>
                    <td>
                      <button
                        className="btn btn-link"
                        title={content.message}
                        onClick={e => {
                          alert('Message:\n' + content.message);
                        }}
                      >
                        <Octicon icon={Info} size="small" />
                      </button>
                    </td>
                    <td>
                      <button className="btn btn-link" onClick={e => this.deleteContent(e, content.id)}>
                        <Octicon icon={Trashcan} size="small" />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div
            onDragOver={this.onDragOver}
            onDragLeave={this.onDragLeave}
            onDrop={this.onDrop}
            onClick={this.openFileDialog}
            className={
              'drag-wrapper my-3 p-5 border-info border text-center' + (this.state.hightlight ? ' Highlight' : '')
            }
            style={{ cursor: 'pointer' }}
          >
            {file && <span> {file}</span>}
            {!file && <span> Drag here to upload new version</span>}
          </div>

          {file && (
            <div>
              <form onSubmit={this.saveContent}>
                <input
                  ref={this.inputMessageRef}
                  placeholder="Why are you uploading a new file?"
                  className="form-control form-control-sm mb-3"
                  type="text"
                  value={message}
                  onChange={e => this.setState({ message: e.target.value })}
                />
              </form>
            </div>
          )}
          <input
            style={{ display: 'none' }}
            ref={this.fileInputRef}
            className="FileInput"
            type="file"
            onChange={this.onFileAdded}
          />
          <button onClick={this.saveContent} className="btn btn-info" disabled={!file || !message}>
            <Octicon icon={CloudUpload} size="small" />
          </button>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevState.file && this.state.file) {
      this.inputMessageRef.current.focus();
    }
  }

  openFileDialog = () => {
    this.fileInputRef.current.click();
  };

  onDragOver = evt => {
    evt.preventDefault();
    this.setState({ hightlight: true });
  };

  onDragLeave = () => {
    this.setState({ hightlight: false });
  };

  onDrop = event => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    const f = files[0];
    this.onValidateFile(f);
  };

  onFileAdded = evt => {
    if (this.props.disabled) return;
    const files = evt.target.files;
    const f = files[0];
    this.onValidateFile(f);
  };

  onValidateFile = f => {
    const reader = new FileReader();
    reader.onload = (() => {
      return e => {
        try {
          JSON.parse(e.target.result);
          this.setState({ hightlight: false, file: f.name, content: e.target.result });
        } catch (e) {
          this.setState({ hightlight: false }, () => {
            ltToast('Content type not allowed. Only JSON is supported', 5000, true);
          });
        }
      };
    })(f);
    reader.readAsText(f);
  };

  saveContent = e => {
    e.preventDefault();
    const { content, message } = this.state;
    const { entity } = this.props;
    Entities.updateContent(entity.id, content, message)
      .then(entity => {
        this.setState({ file: null, content: null });
        this.props.onEntityChange(entity);
      })
      .catch(e => {
        ltToast('Unable to update content: ' + e.message, 5000, true);
      });
  };

  downloadContent = (e, content_id) => {
    if (e) {
      e.preventDefault();
    }
    const { entity } = this.props;
    Entities.getContent(entity.id, content_id)
      .then(data => {
        console.log(data.content);
        const title = slugify(entity.title);
        const now = slugify(new Date().toISOString());
        download(
          new Blob([data.content]),
          `${title}_${entity.category_code}_${content_id}_${now}.json`,
          'application/json; charset=utf-8'
        );
      })
      .catch(e => {
        ltToast('Unable to download content: ' + e.message, 5000, true);
      });
  };

  deleteContent = (e, content_id) => {
    if (e) {
      e.preventDefault();
    }
    const ok = window.confirm('Are you sure you want to delete it?');
    if (!ok) {
      return;
    }
    const { entity, onEntityChange } = this.props;
    Entities.deleteContent(entity.id, content_id)
      .then(entity => {
        onEntityChange(entity);
      })
      .catch(e => {
        ltToast('Unable to delete content: ' + e.message, 5000, true);
      });
  };
}

export default EntityContentCard;
