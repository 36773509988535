import Categories from './categories';

export const REQUEST_CATEGORIES = 'REQUEST_CATEGORIES';
export const RECEIVE_CATEGORIES = 'RECEIVE_CATEGORIES';
export const FAILED_CATEGORIES = 'FAILED_CATEGORIES';

export function doGetCategories() {
  return dispatch => {
    dispatch(requestCategories());
    return Categories.getCategories()
      .then(json => {
        dispatch(receiveCategories(json));
      })
      .catch(error => dispatch(failedFetchCategories(error.message)));
  };
}

function requestCategories() {
  return {
    type: REQUEST_CATEGORIES
  };
}

function receiveCategories(json) {
  return {
    type: RECEIVE_CATEGORIES,
    data: json
  };
}

function failedFetchCategories(error) {
  console.log(error);
  return {
    type: FAILED_CATEGORIES
  };
}
