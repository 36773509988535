import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { doLogout } from '../../lib/models/user/userActions';
import settings from '../../lib/settings';

class TopMenu extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  render() {
    const { user } = this.props;
    if (!user.data) {
      return <div />;
    }

    return (
      <nav className="navbar navbar-expand-lg navbar-dark" style={{ backgroundColor: '#2E7DE0' }}>
        <Link to="/" className="navbar-brand" href="#">
          {settings.appDescription}
        </Link>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            {this.getMenuItem('/functionalities', 'Functionalities')}
            {this.getMenuItem('/entities', 'Entities')}
            {this.getMenuItem('/examples', 'Examples')}
          </ul>
          <ul className="navbar-nav">
            {this.getMenuItem('/', 'Home')}

            {user.data.sl_admin ? this.getMenuItem('/admin/', 'Admin') : undefined}
            {this.getMenuItem('/logout', 'Logout', this.doLogout)}
          </ul>
        </div>
      </nav>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { user: oldUser } = prevProps;
    const { user: nextUser, history } = this.props;
    if (oldUser.data && !nextUser.data) {
      history.push('/');
    }
  }

  getMenuItem(link, desc, onClick) {
    let cls;
    let srText = '';
    if (link === '/') {
      cls = this.props.location.pathname === link ? 'active' : undefined;
      srText = '(current)';
    } else {
      cls = this.props.location.pathname.indexOf(link) === 0 ? 'active' : undefined;
      srText = '(current)';
    }

    return (
      <li className={'nav-item ' + cls}>
        <Link
          to={link}
          className="nav-link"
          onClick={e => {
            if (onClick) {
              onClick(e);
            }
          }}
        >
          {desc}
          <span className="sr-only">{srText}</span>
        </Link>
      </li>
    );
  }

  doLogout = event => {
    event.preventDefault();
    this.props.dispatch(doLogout());
  };
}

function mapStateToProps(state) {
  const { user } = state;

  return {
    user
  };
}

export default withRouter(connect(mapStateToProps)(TopMenu));
