import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Octicon, { Plus, Dash, Trashcan } from '@githubprimer/octicons-react';
import { ltToast } from '../Common/LTToast';

class EntitySitesCard extends PureComponent {
  static propTypes = {
    obj: PropTypes.object.isRequired,
    clazz: PropTypes.func.isRequired,
    sites: PropTypes.object.isRequired,
    onObjChange: PropTypes.func.isRequired
  };

  state = {
    editValue: '',
    editing: false
  };

  inputRef = React.createRef();

  render() {
    const { obj } = this.props;
    const { editValue, editing } = this.state;
    return (
      <div className="card border-info">
        <div className="card-header bg-info text-white">
          <strong>Sites</strong>
        </div>
        <div className="card-body">
          {obj.sites.length === 0 && (
            <div>
              <dl className="row">
                <dt className="col-12 col-md-4 col-lg-3">Main site</dt>
                <dd className="col-12 col-md-8 col-lg-9">{obj.site_description}</dd>
              </dl>
              <p>No linked sites</p>
            </div>
          )}
          {obj.sites.length > 0 && (
            <table className="table table-sm">
              <tbody>
                <tr>
                  <td>
                    <em>{obj.site_description}</em>
                  </td>
                  <td>
                    <button className="btn btn-link disabled" disabled={true}>
                      <Octicon icon={Trashcan} size="small" />
                    </button>
                  </td>
                </tr>
                {obj.sites.map((site, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        {site.code} {site.description}
                      </td>
                      <td>
                        <button
                          className="btn btn-link"
                          onClick={e => {
                            this.deleteSite(site.id);
                          }}
                        >
                          <Octicon icon={Trashcan} size="small" />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          {editing && (
            <select
              className="form-control form-control-sm"
              ref={this.inputRef}
              value={editValue}
              onChange={e => {
                const { value } = e.target;
                this.setState({ editValue: '', editing: false }, () => {
                  this.addSite(value);
                });
              }}
            >
              <option />
              {this.getUnlinkedSites().map((site, i) => {
                return (
                  <option key={i} value={site.id}>
                    {site.code} {site.description}
                  </option>
                );
              })}
            </select>
          )}
          <div className="mt-3" title="Add Site">
            <button onClick={this.toggleSite} className="btn btn-info">
              <Octicon icon={editing ? Dash : Plus} size="small" />
            </button>
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevState.editing && this.state.editing) {
      this.inputRef.current.focus();
    }
  }

  toggleSite = () => {
    if (this.state.editing) {
      this.setState({ editValue: '', editing: false });
    } else {
      this.setState({ editValue: '', editing: true });
    }
  };

  getUnlinkedSites = () => {
    const { sites, obj } = this.props;
    const keys = Object.keys(sites.data);
    const leftsites = [];
    keys.forEach(k => {
      if (sites.data[k].code === obj.site_code) {
        return;
      }
      for (let i = 0, site; (site = obj.sites[i]); i++) {
        if (site.id === sites.data[k].id) {
          return;
        }
      }
      leftsites.push(sites.data[k]);
    });
    return leftsites;
  };

  addSite = site_id => {
    const { obj, clazz } = this.props;
    clazz
      .addSite(obj.id, site_id)
      .then(obj => {
        this.props.onObjChange(obj);
      })
      .catch(e => {
        ltToast('Unable to add site: ' + e.message, 5000, true);
      });
  };

  deleteSite = site_id => {
    const { obj, clazz } = this.props;
    clazz
      .deleteSite(obj.id, site_id)
      .then(obj => {
        this.props.onObjChange(obj);
      })
      .catch(e => {
        ltToast('Unable to add site: ' + e.message, 5000, true);
      });
  };
}

export default EntitySitesCard;
