import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ltToast } from './LTToast';

class TagInput extends PureComponent {
  static propTypes = {
    tags: PropTypes.array.isRequired,
    values: PropTypes.string.isRequired,
    onAddTag: PropTypes.func.isRequired
  };

  inputRef = React.createRef();

  state = {
    editValue: '',
    idxTag: -1,
    tagsFound: []
  };

  render() {
    const { editValue, tagsFound, idxTag } = this.state;
    return (
      <div>
        <input
          placeholder="Enter tag, then type ',' or press enter to add it"
          type="text"
          ref={this.inputRef}
          className="form-control"
          onKeyDown={this.handleKeyDown}
          value={editValue}
          onChange={this.handleChange}
        />
        {editValue.length > 1 && tagsFound.length > 0 && (
          <div className="dropdown">
            <div className="dropdown-menu show">
              {tagsFound.map((tag, i) => {
                return (
                  <div
                    onClick={e => this.addTag(tag.tag)}
                    className={'dropdown-item' + (idxTag === i ? ' active' : '')}
                    key={i}
                  >
                    {tag.tag} ({tag.count})
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }

  handleKeyDown = e => {
    const { idxTag, tagsFound, editValue } = this.state;
    // arrow up/down button should select next/previous list element
    if (e.keyCode === 27) {
      this.setState({ editValue: '', idxTag: -1 });
    } else if (e.keyCode === 38 && idxTag > 0) {
      this.setState(prevState => ({
        idxTag: prevState.idxTag - 1
      }));
    } else if (e.keyCode === 40 && idxTag < tagsFound.length - 1) {
      e.preventDefault();
      this.setState(prevState => ({
        idxTag: prevState.idxTag + 1
      }));
    } else if (e.keyCode === 13 && idxTag >= 0) {
      e.preventDefault();
      this.addTag(tagsFound[idxTag].tag);
    } else if (e.keyCode === 13) {
      e.preventDefault();
      this.addTag(editValue);
    }
  };

  addTag = tag => {
    const { values } = this.props;
    const avals = values.split(',').map(v => v.trim());
    if (avals.indexOf(tag) >= 0) {
      return false;
    }
    this.setState({ tagsFound: [], editValue: '', idxTag: -1 }, () => {
      this.props.onAddTag(tag);
      this.inputRef.current.focus();
    });
  };

  handleChange = e => {
    const value = e.target.value.toLowerCase();
    if (value.indexOf(',') >= 0) {
      const tags = value
        .split(',')
        .map(t => t.trim())
        .filter(t => !!t);
      if (tags.length > 1) {
        ltToast('Please insert one tag at a time', 2000, true);
        return;
      }
      for (let i = 0; i < tags.length; i++) {
        const tag = tags[i].trim();
        if (tag) {
          this.addTag(tag);
        }
      }
      return;
    }
    this.setState({ editValue: value, idxTag: -1 }, () => {
      this.searchTag();
    });
  };

  searchTag = () => {
    const { tags, values } = this.props;
    const { editValue } = this.state;
    if (!editValue) {
      this.setState({ tagsFound: [], idxTag: -1 });
      return;
    }
    const avals = values.split(',').map(v => v.trim());
    const tagsFound = tags.filter(t => {
      const tag = t.tag.toLowerCase();
      if (avals.indexOf(tag) >= 0) {
        return false;
      }
      return tag.indexOf(editValue) >= 0;
    });
    this.setState({
      tagsFound
    });
  };
}

export default TagInput;
