import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import checkUser from './UserComponent';
import { setTitle } from '../lib/utils/windowutils';
import Entities from '../lib/models/Entities';
import Examples from '../lib/models/Examples';
import Functionalities from '../lib/models/Functionalities';
import { Link } from 'react-router-dom';
import Octicon, { Plus } from '@githubprimer/octicons-react';
import { getFormattedStringDate } from '../lib/utils/dateUtils';

class MainComp extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    setTitle('Home');
  }

  state = {
    entities: { rows: [] },
    functionalities: { rows: [] },
    examples: { rows: [] }
  };

  render() {
    const { user } = this.props;
    const { entities, functionalities, examples } = this.state;
    return (
      <div className="mt-3">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">
            <h5>
              Welcome <strong>{user.data.name}</strong>
            </h5>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6  mt-3">
            <div className="card border-info">
              <div className="card-header bg-info text-white">
                <Link className="text-white" to="/functionalities" title="Functionalities">
                  <strong>Latest Functionalities</strong>
                </Link>
                <div className="float-right">
                  <Link className="btn btn-info btn-sm" to="/functionalities/create" title="Add new functionality">
                    <Octicon icon={Plus} />
                  </Link>
                </div>
              </div>
              <div className="card-body">
                <div className="list-group">
                  {functionalities.rows.map((f, i) => {
                    return (
                      <div key={i} className="list-group-item">
                        <div className="d-flex w-100 justify-content-between">
                          <Link className="mb-1" to={`/functionalities/${f.id}`}>
                            {f.title}
                          </Link>
                          <small>
                            <em>{f.created_by}</em>
                            <br />
                            <em>{getFormattedStringDate(f.created_at)}</em>
                          </small>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mt-3">
            <div className="card border-info">
              <div className="card-header bg-info text-white">
                <Link className="text-white" to="/entities" title="Entities">
                  <strong>Latest Entities</strong>
                </Link>
                <div className="float-right">
                  <Link className="btn btn-info btn-sm" to="/entities/create" title="Add new entity">
                    <Octicon icon={Plus} />
                  </Link>
                </div>
              </div>
              <div className="card-body">
                <div className="list-group">
                  {entities.rows.map((e, i) => {
                    return (
                      <div key={i} className="list-group-item">
                        <div className="d-flex w-100 justify-content-between">
                          <Link className="mb-1" to={`/entities/${e.id}`}>
                            {e.title}
                          </Link>
                          <small>
                            <em>{e.created_by}</em>
                            <br />
                            <em>{getFormattedStringDate(e.created_at)}</em>
                          </small>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mt-3">
            <div className="card border-info">
              <div className="card-header bg-info text-white">
                <Link className="text-white" to="/examples" title="Examples">
                  <strong>Latest Examples</strong>
                </Link>
                <div className="float-right">
                  <Link className="btn btn-info btn-sm" to="/examples/create" title="Add new example">
                    <Octicon icon={Plus} />
                  </Link>
                </div>
              </div>
              <div className="card-body">
                <div className="list-group">
                  {examples.rows.map((e, i) => {
                    return (
                      <div key={i} className="list-group-item">
                        <div className="d-flex w-100 justify-content-between">
                          <Link className="mb-1" to={`/examples/${e.id}`}>
                            {e.title}
                          </Link>
                          <small>
                            <em>{e.created_by}</em>
                            <br />
                            <em>{getFormattedStringDate(e.created_at)}</em>
                          </small>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    const p = [
      Entities.find({ order_by: 'created_at', sort: 'desc', limit: 5 }),
      Functionalities.find({ order_by: 'created_at', sort: 'desc', limit: 5 }),
      Examples.find({ order_by: 'created_at', sort: 'desc', limit: 5 })
    ];
    Promise.all(p).then(([entities, functionalities, examples]) =>
      this.setState({
        entities,
        functionalities,
        examples
      })
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;

  return {
    user
  };
}

const Main = connect(mapStateToProps)(checkUser(MainComp));
export default Main;
