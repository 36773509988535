import { deleteData, getData, postData, putData } from '../utils/httputils';

class Functionalities {
  static apiPath = '/api/v1/functionalities';

  static find(params) {
    const { limit, offset, order_by, sort, search } = params;
    const searchQuery = search ? '&search=' + search : '';
    return getData(
      `${Functionalities.apiPath}?limit=${limit || 10}&offset=${offset || 0}&order_by=${order_by || 'id'}&sort=${sort ||
        'desc'}${searchQuery}`
    );
  }

  static get(id) {
    return getData(`${Functionalities.apiPath}/${id}`);
  }

  static create(data) {
    return postData(Functionalities.apiPath, data);
  }

  static update(id, field, value) {
    return putData(`${Functionalities.apiPath}/${id}`, { field, value });
  }

  static getTags() {
    return getData(`${Functionalities.apiPath}/tags`);
  }

  static updateTags(id, tags) {
    return postData(`${Functionalities.apiPath}/${id}/tags`, tags);
  }

  static addSite = (id, site_id) => {
    return postData(`${Functionalities.apiPath}/${id}/sites`, { site_id });
  };

  static deleteSite = (id, site_id) => {
    return deleteData(`${Functionalities.apiPath}/${id}/sites/${site_id}`);
  };

  static addEntity = (id, entity_id) => {
    return postData(`${Functionalities.apiPath}/${id}/entities`, [entity_id]);
  };

  static deleteEntity = (id, entity_id) => {
    return deleteData(`${Functionalities.apiPath}/${id}/entities/${entity_id}`);
  };

  static delete = id => {
    return deleteData(`${Functionalities.apiPath}/${id}`);
  };

  static gtContent = id => {
    return getData(`${Functionalities.apiPath}/${id}/contents/zip`);
  };

  static gtContentUrl = id => {
    return `${Functionalities.apiPath}/${id}/contents/zip`;
  };
}

export default Functionalities;
