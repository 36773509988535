import User from './user';
import { doGetSites } from '../sites/';
import { doGetCategories } from '../categories/';
import { doGetModules } from '../modules';

export const REQUEST_USER_ME = 'REQUEST_USER_ME';
export const RECEIVE_USER_ME = 'RECEIVE_USER_ME';
export const FAILED_USER_ME = 'FAILED_USER_ME';

export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const RECEIVE_LOGIN = 'RECEIVE_LOGIN';
export const FAILED_LOGIN = 'FAILED_LOGIN';
export const RESET_LOGIN = 'RESET_LOGIN';

export const REQUEST_LOGOUT = 'REQUEST_LOGOUT';
export const RECEIVE_LOGOUT = 'RECEIVE_LOGOUT';

export function doGetUserMe() {
  return dispatch => {
    dispatch(requestUserMe());
    return User.getMe()
      .then(json => {
        dispatch(reciveUserMe(json));
        dispatch(doGetSites());
        dispatch(doGetCategories());
        dispatch(doGetModules());
      })
      .catch(error => {
        // clearSessionToken();
        dispatch(failedUserMe());
      });
  };
}

export function doLogout() {
  // clearSessionToken();
  return async dispatch => {
    dispatch(requestLogout());
    try {
      await User.doLogout();
      dispatch(receiveLogout());
    } catch (err) {
      dispatch(receiveLogout());
    }
  };
}

export function requestLogout() {
  return {
    type: REQUEST_LOGOUT
  };
}

export function receiveLogout() {
  return {
    type: RECEIVE_LOGOUT
  };
}

export function doLogin(tokenId) {
  return dispatch => {
    dispatch(requestLogin());
    return User.doLogin(tokenId)
      .then(sessionId => {
        // storeSessionToken(sessionId);
        dispatch(receiveLogin(sessionId));
        setTimeout(() => {
          dispatch(doGetUserMe());
        }, 200);
      })
      .catch(error => {
        dispatch(failedLogin(error.message));
      });
  };
}

export function resetLogin() {
  return {
    type: RESET_LOGIN
  };
}

function requestUserMe() {
  return {
    type: REQUEST_USER_ME
  };
}

function reciveUserMe(json) {
  return {
    type: RECEIVE_USER_ME,
    data: json
  };
}

function failedUserMe() {
  return {
    type: FAILED_USER_ME
  };
}

function requestLogin() {
  return {
    type: REQUEST_LOGIN
  };
}

function receiveLogin(json) {
  return {
    type: RECEIVE_LOGIN,
    token: json
  };
}

function failedLogin(error) {
  return {
    type: FAILED_LOGIN,
    reason: error
  };
}
