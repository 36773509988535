import { deleteData, getData, postData, putData } from '../../utils/httputils';

class Categories {
  static apiPath = '/api/v1/categories';
  static adminApiPath = '/api/v1/admin/categories';

  static getCategories() {
    return getData(Categories.apiPath);
  }

  static create(data) {
    return postData(Categories.adminApiPath, data);
  }

  static find(params) {
    const { limit, offset, order_by, sort, search } = params;
    const searchQuery = search ? '&search=' + search : '';
    return getData(
      `${Categories.adminApiPath}?limit=${limit || 10}&offset=${offset || 0}&order_by=${order_by || 'id'}&sort=${sort ||
        'desc'}${searchQuery}`
    );
  }

  static get(id) {
    return getData(`${Categories.adminApiPath}/${id}`);
  }

  static update(id, field, value) {
    return putData(`${Categories.adminApiPath}/${id}`, { field, value });
  }

  static delete = id => {
    return deleteData(`${Categories.adminApiPath}/${id}`);
  };
}

export default Categories;
