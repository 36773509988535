import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import checkUser from '../UserComponent';
import { Route, Switch } from 'react-router-dom';
import FunctionalityMain from './FunctionalityMain';
import FunctionalityCreate from './FunctionalityCreate';
import FunctionalityView from './FunctionalityView';

class EntityRootComp extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  render() {
    const { match } = this.props;
    return (
      <div className="entity-wrapper mt-3">
        <Switch>
          <Route exact path={match.path} component={FunctionalityMain} />
          <Route exact path={`${match.path}/create`} component={FunctionalityCreate} />
          <Route exact path={`${match.path}/:id`} component={FunctionalityView} />
        </Switch>
      </div>
    );
  }
}

const EntityRoot = checkUser(EntityRootComp);

export default EntityRoot;
