import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import checkUser from '../UserComponent';
import { Route, Switch } from 'react-router-dom';
import EntityCreate from './EntityCreate';
import EntityMain from './EntityMain';
import EntityView from './EntityView';

class EntityRootComp extends PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  render() {
    const { match } = this.props;
    return (
      <div className="entity-wrapper mt-3">
        <Switch>
          <Route exact path={match.path} component={EntityMain} />
          <Route exact path={`${match.path}/create`} component={EntityCreate} />
          <Route exact path={`${match.path}/:id`} component={EntityView} />
        </Switch>
      </div>
    );
  }
}

const EntityRoot = checkUser(EntityRootComp);

export default EntityRoot;
