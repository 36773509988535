import { deleteData, getData, postData, putData } from '../../utils/httputils';

class Sites {
  static apiPath = '/api/v1/sites';
  static adminApiPath = '/api/v1/admin/sites';

  static getSites() {
    return getData(Sites.apiPath);
  }

  static create(data) {
    return postData(Sites.adminApiPath, data);
  }

  static find(params) {
    const { limit, offset, order_by, sort, search } = params;
    const searchQuery = search ? '&search=' + search : '';
    return getData(
      `${Sites.adminApiPath}?limit=${limit || 10}&offset=${offset || 0}&order_by=${order_by || 'id'}&sort=${sort ||
        'desc'}${searchQuery}`
    );
  }

  static get(id) {
    return getData(`${Sites.adminApiPath}/${id}`);
  }

  static update(id, field, value) {
    return putData(`${Sites.adminApiPath}/${id}`, { field, value });
  }

  static delete = id => {
    return deleteData(`${Sites.adminApiPath}/${id}`);
  };
}

export default Sites;
