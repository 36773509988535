import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { setTitle } from '../../lib/utils/windowutils';
import Examples from '../../lib/models/Examples';
import Loading from '../Common/Loading';
import ReactMarkdown from 'react-markdown';
import { getFormattedStringDate } from '../../lib/utils/dateUtils';
import { handleOnKeyDown } from '../../lib/utils/commonutils';
import { ltToast } from '../Common/LTToast';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ExampleContentCard from './ExampleContentCard';
import DescriptionEdit from '../Common/DescriptionEdit';
import qs from 'qs';

class ExampleView extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
  };

  fileInputRef = React.createRef();

  state = {
    example: false,
    editField: '',
    editValue: '',
    editing: false,
    currentContent: 0
  };

  refInput = React.createRef();

  render() {
    const { example, editing, editField, editValue, currentContent } = this.state;
    const { match, user } = this.props;
    if (!example) {
      return <Loading />;
    }

    return (
      <div className="row">
        <div className="mb-3 col-12 col-md-6 col-lg-4">
          <div className="card border-info mb-3">
            <div className="card-header bg-info text-white">
              <strong>Detail</strong>
            </div>
            <div className="card-body">
              <dl className="row">
                <dt className="col-12 col-md-4 col-lg-3">Title</dt>
                <dd
                  className="col-12 col-md-8 col-lg-9"
                  onClick={e => this.setState({ editField: 'title', editValue: example.title, editing: true })}
                >
                  {(!editing || editField !== 'title') && <DescriptionEdit>{example.title}</DescriptionEdit>}
                  {editing && editField === 'title' && (
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      value={editValue}
                      ref={this.refInput}
                      onBlur={this.saveEdit}
                      onKeyDown={e => handleOnKeyDown(e, this.saveEdit)}
                      onChange={e => this.setState({ editValue: e.target.value })}
                    />
                  )}
                </dd>
                <dt className="col-12 col-md-4 col-lg-3">Description</dt>

                <dd
                  className="col-12 col-md-8 col-lg-9"
                  onClick={e =>
                    this.setState({ editField: 'description', editValue: example.description, editing: true })
                  }
                >
                  {(!editing || editField !== 'description') && (
                    <DescriptionEdit>
                      <ReactMarkdown source={example.description} />
                    </DescriptionEdit>
                  )}
                  {editing && editField === 'description' && (
                    <div>
                      <span>
                        Use Markdown:{' '}
                        <a
                          href="https://en.support.wordpress.com/markdown-quick-reference/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Help
                        </a>
                      </span>
                      <textarea
                        className="form-control form-control-sm"
                        value={editValue}
                        autoCorrect="off"
                        rows={5}
                        ref={this.refInput}
                        onBlur={this.saveEdit}
                        onChange={e => this.setState({ editValue: e.target.value })}
                      />
                    </div>
                  )}
                </dd>
                <dt className="col-12 col-md-4 col-lg-3">Tags</dt>
                <dd
                  className="col-12 col-md-8 col-lg-9"
                  onClick={e =>
                    this.setState({
                      editField: 'tags',
                      editValue: example.tags.map(tag => tag).join(','),
                      editing: true
                    })
                  }
                >
                  {(!editing || editField !== 'tags') && (
                    <DescriptionEdit>
                      {example.tags.map((tag, i) => {
                        return (
                          <Link to={`./?q=tag:${tag}`} key={i}>
                            <span key={i} className="badge badge-primary mr-2">
                              {tag}
                            </span>
                          </Link>
                        );
                      })}
                    </DescriptionEdit>
                  )}
                  {editing && editField === 'tags' && (
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      value={editValue}
                      ref={this.refInput}
                      onBlur={this.saveTags}
                      onKeyDown={e => handleOnKeyDown(e, this.saveTags)}
                      onChange={e => this.setState({ editValue: e.target.value })}
                    />
                  )}
                </dd>
                <dt className="col-12 col-md-4 col-lg-3">Created by</dt>
                <dd className="col-12 col-md-8 col-lg-9">
                  {example.created_by} on {getFormattedStringDate(example.created_at)}
                </dd>
                {example.updated_at && <dt className="col-12 col-md-4 col-lg-3">Updated by</dt>}
                {example.updated_at && (
                  <dd className="col-12 col-md-8 col-lg-9">
                    {example.updated_by} on {getFormattedStringDate(example.updated_at)}
                  </dd>
                )}
              </dl>
            </div>
          </div>

          <div className="card border-info mb-3">
            <div className="card-header bg-info text-white">
              <strong>Content history</strong>
            </div>
            <div className="card-body">
              <div className="list-group">
                {example.content.map((content, i) => {
                  return (
                    <Link
                      key={i}
                      className={
                        'list-group-item list-group-item-action' + (currentContent === i ? ' list-group-item-info' : '')
                      }
                      to={`${match.url}?content=${i}`}
                    >
                      Updated by <strong>{content.created_by}</strong>{' '}
                      <em>{getFormattedStringDate(content.created_at)}</em>
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>

          {user.data.sl_admin && (
            <div className="card border-danger">
              <div className="card-header bg-danger text-white">
                <strong>DANGER ZONE</strong>
              </div>
              <div className="card-body">
                <button className="btn btn-danger" onClick={this.handleDelete}>
                  DELETE
                </button>
              </div>
            </div>
          )}
        </div>

        <ExampleContentCard
          example={example}
          match={match}
          currentContent={currentContent}
          onExampleChange={this.loadExample}
        />

        {/*
        <div className="mb-3 col-12 col-md-6 col-lg-4">
          <pre>{JSON.stringify(example, null, 3)}</pre>
        </div> */}
      </div>
    );
  }

  componentDidMount() {
    this.loadExample();
    this._setCurrentContent();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.example && this.state.example.id && !prevState.example.id) {
      setTitle(this.state.example.title);
    }
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.loadExample();
    }
    if (!prevState.editing && this.state.editing && this.refInput.current) {
      this.refInput.current.focus();
    }
    if (prevProps.location.search !== this.props.location.search) {
      this._setCurrentContent();
    }
  }

  handleDelete = e => {
    e.preventDefault();
    const ok = window.confirm('Are you sure?');
    if (ok) {
      const { example } = this.state;
      Examples.delete(example.id)
        .then(() => {
          this.props.history.push('/examples');
        })
        .catch(e => {
          ltToast('Unable to delete functionality: ' + e.message, 5000, true);
        });
    }
  };

  _setCurrentContent = () => {
    const { search } = this.props.location;
    const parameters = qs.parse(search, { ignoreQueryPrefix: true });
    const { content } = parameters;
    if (!isNaN(content)) {
      this.setState({ currentContent: Number(content) });
    }
  };

  saveTags = () => {
    const { editValue, example } = this.state;
    this.setState({ editField: '', editValue: '', editing: false });
    this.refInput = React.createRef();
    if (example.tags.map(tag => tag).join(',') !== editValue) {
      Examples.updateTags(example.id, editValue.split(','))
        .then(example => {
          this.setState({ example });
        })
        .catch(e => {
          ltToast('Unable to update tags: ' + e.message, 5000, true);
        });
    }
  };

  saveEdit = () => {
    const { editField, editValue, example } = this.state;
    this.setState({ editField: '', editValue: '', editing: false });
    this.refInput = React.createRef();
    if (example[editField] !== editValue) {
      Examples.update(example.id, editField, editValue)
        .then(example => {
          this.setState({ example });
        })
        .catch(e => {
          ltToast('Unable to save: ' + e.message, 5000, true);
        });
    }
  };

  loadExample = () => {
    const { match } = this.props;
    const { params } = match;
    Examples.get(params.id)
      .then(example => {
        this.setState({
          example
        });
      })
      .catch(e => console.error('err', e.message));
  };
}

function mapStateToProps(state) {
  const { categories, sites, user } = state;

  return {
    categories,
    sites,
    user
  };
}

export default connect(mapStateToProps)(ExampleView);
