import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { setTitle } from '../../lib/utils/windowutils';
import Entities from '../../lib/models/Entities';
import Loading from '../Common/Loading';
import ReactMarkdown from 'react-markdown';
import { getFormattedStringDate } from '../../lib/utils/dateUtils';
import { handleOnKeyDown } from '../../lib/utils/commonutils';
import { ltToast } from '../Common/LTToast';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SitesCard from '../Common/SitesCard';
import EntityContentCard from './EntityContentCard';
import DescriptionEdit from '../Common/DescriptionEdit';

class EntityView extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    categories: PropTypes.object.isRequired,
    sites: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
  };

  fileInputRef = React.createRef();

  state = {
    entity: false,
    editField: '',
    editValue: '',
    editing: false
  };

  refInput = React.createRef();

  render() {
    const { categories, sites, user } = this.props;
    const { entity, editing, editField, editValue } = this.state;
    if (!entity) {
      return <Loading />;
    }

    return (
      <div className="row">
        <div className="mb-3 col-12 col-md-6 col-lg-4">
          <div className="card border-info">
            <div className="card-header bg-info text-white">
              <strong>Detail</strong>
            </div>
            <div className="card-body">
              <dl className="row">
                <dt className="col-12 col-md-4 col-lg-3">Title</dt>
                <dd
                  className="col-12 col-md-8 col-lg-9"
                  onClick={e => this.setState({ editField: 'title', editValue: entity.title, editing: true })}
                >
                  {(!editing || editField !== 'title') && <DescriptionEdit>{entity.title}</DescriptionEdit>}
                  {editing && editField === 'title' && (
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      value={editValue}
                      ref={this.refInput}
                      onBlur={this.saveEdit}
                      onKeyDown={e => handleOnKeyDown(e, this.saveEdit)}
                      onChange={e => this.setState({ editValue: e.target.value })}
                    />
                  )}
                </dd>
                <dt className="col-12 col-md-4 col-lg-3">Description</dt>

                <dd
                  className="col-12 col-md-8 col-lg-9"
                  onClick={e =>
                    this.setState({ editField: 'description', editValue: entity.description, editing: true })
                  }
                >
                  {(!editing || editField !== 'description') && (
                    <DescriptionEdit>
                      <ReactMarkdown source={entity.description} />
                    </DescriptionEdit>
                  )}
                  {editing && editField === 'description' && (
                    <div>
                      <span>
                        Use Markdown:{' '}
                        <a
                          href="https://en.support.wordpress.com/markdown-quick-reference/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Help
                        </a>
                      </span>
                      <textarea
                        className="form-control form-control-sm"
                        value={editValue}
                        autoCorrect="off"
                        rows={5}
                        ref={this.refInput}
                        onBlur={this.saveEdit}
                        onChange={e => this.setState({ editValue: e.target.value })}
                      />
                    </div>
                  )}
                </dd>
                <dt className="col-12 col-md-4 col-lg-3">Category</dt>
                <dd
                  className="col-12 col-md-8 col-lg-9"
                  onClick={e =>
                    this.setState({ editField: 'category_id', editValue: entity.category_id, editing: true })
                  }
                >
                  {(!editing || editField !== 'category_id') && <DescriptionEdit>{entity.category}</DescriptionEdit>}
                  {editing && editField === 'category_id' && (
                    <select
                      className="form-control"
                      onChange={e => this.setState({ editValue: e.target.value })}
                      onBlur={this.saveEdit}
                      value={editValue}
                    >
                      <option />
                      {categories.data.map((category, i) => {
                        return (
                          <option key={i} value={category.id}>
                            {category.description}
                          </option>
                        );
                      })}
                    </select>
                  )}
                </dd>
                <dt className="col-12 col-md-4 col-lg-3">Tags</dt>
                <dd
                  className="col-12 col-md-8 col-lg-9"
                  onClick={e =>
                    this.setState({
                      editField: 'tags',
                      editValue: entity.tags.map(tag => tag).join(','),
                      editing: true
                    })
                  }
                >
                  {(!editing || editField !== 'tags') && (
                    <DescriptionEdit>
                      {entity.tags.map((tag, i) => {
                        return (
                          <Link to={`./?q=tag:${tag}`} key={i}>
                            <span key={i} className="badge badge-primary mr-2">
                              {tag}
                            </span>
                          </Link>
                        );
                      })}
                    </DescriptionEdit>
                  )}
                  {editing && editField === 'tags' && (
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      value={editValue}
                      ref={this.refInput}
                      onBlur={this.saveTags}
                      onKeyDown={e => handleOnKeyDown(e, this.saveTags)}
                      onChange={e => this.setState({ editValue: e.target.value })}
                    />
                  )}
                </dd>
                <dt className="col-12 col-md-4 col-lg-3">Created by</dt>
                <dd className="col-12 col-md-8 col-lg-9">
                  {entity.created_by} on {getFormattedStringDate(entity.created_at)}
                </dd>
                {entity.updated_at && <dt className="col-12 col-md-4 col-lg-3">Updated by</dt>}
                {entity.updated_at && (
                  <dd className="col-12 col-md-8 col-lg-9">
                    {entity.updated_by} on {getFormattedStringDate(entity.updated_at)}
                  </dd>
                )}

                {entity.functionalities.length > 0 && (
                  <dt key={1} className="col-12 col-md-4 col-lg-3">
                    Functionalities
                  </dt>
                )}
                {entity.functionalities.length > 0 && (
                  <dd key={2} className="col-12 col-md-8 col-lg-9">
                    <table className="table table-sm table-borderless">
                      {entity.functionalities.map((f, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <Link to={`/functionalities/${f.id}`}>{f.title}</Link>
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </dd>
                )}
              </dl>
            </div>
          </div>
        </div>
        <div className="mb-3 col-12 col-md-6 col-lg-4">
          <SitesCard obj={entity} clazz={Entities} sites={sites} onObjChange={entity => this.setState({ entity })} />
        </div>
        <div className="mb-3 col-12 col-md-6 col-lg-4">
          <EntityContentCard entity={entity} onEntityChange={entity => this.setState({ entity })} />
        </div>
        {user.data.sl_admin && (
          <div className="mb-3 col-12 col-md-6 col-lg-4">
            <div className="card border-danger">
              <div className="card-header bg-danger text-white">
                <strong>DANGER ZONE</strong>
              </div>
              <div className="card-body">
                <button className="btn btn-danger" onClick={this.handleDelete}>
                  DELETE
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  componentDidMount() {
    this.loadEntity();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.entity && this.state.entity.id && !prevState.entity.id) {
      setTitle(this.state.entity.title);
    }
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.loadEntity();
    }
    if (!prevState.editing && this.state.editing && this.refInput.current) {
      this.refInput.current.focus();
    }
  }

  handleDelete = e => {
    e.preventDefault();
    const ok = window.confirm('Are you sure?');
    if (ok) {
      const { entity } = this.state;
      Entities.delete(entity.id)
        .then(() => {
          this.props.history.push('/entities');
        })
        .catch(e => {
          ltToast('Unable to delete entity: ' + e.message, 5000, true);
        });
    }
  };

  saveTags = () => {
    const { editValue, entity } = this.state;
    this.setState({ editField: '', editValue: '', editing: false });
    this.refInput = React.createRef();
    if (entity.tags.map(tag => tag).join(',') !== editValue) {
      Entities.updateTags(entity.id, editValue.split(','))
        .then(entity => {
          this.setState({ entity });
        })
        .catch(e => {
          ltToast('Unable to update tags: ' + e.message, 5000, true);
        });
    }
  };

  saveEdit = () => {
    const { editField, editValue, entity } = this.state;
    this.setState({ editField: '', editValue: '', editing: false });
    this.refInput = React.createRef();
    if (entity[editField] !== editValue) {
      Entities.update(entity.id, editField, editValue)
        .then(entity => {
          this.setState({ entity });
        })
        .catch(e => {
          ltToast('Unable to save: ' + e.message, 5000, true);
        });
    }
  };

  loadEntity = () => {
    const { match } = this.props;
    const { params } = match;
    Entities.get(params.id)
      .then(entity => {
        this.setState({
          entity
        });
      })
      .catch(e => console.error('err', e.message));
  };
}

function mapStateToProps(state) {
  const { categories, sites, user } = state;

  return {
    categories,
    sites,
    user
  };
}

export default connect(mapStateToProps)(EntityView);
