import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Loading from '../Common/Loading';
import DescriptionEdit from '../Common/DescriptionEdit';
import { handleOnKeyDown } from '../../lib/utils/commonutils';
import ReactMarkdown from 'react-markdown';
import { setTitle } from '../../lib/utils/windowutils';
import Distributors from '../../lib/models/Distributors';
import { ltToast } from '../Common/LTToast';
import { Link } from 'react-router-dom';
import Octicon, { Plus, Link as IconLink } from '@githubprimer/octicons-react';
import Customers from '../../lib/models/Customers';
import Sites from '../../lib/models/sites/sites';

class DistributorView extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  state = {
    distributor: false,
    customers: { rows: [] },
    sites: { rows: [] },
    editField: '',
    editValue: '',
    editing: false,
    currentCustomer: -1
  };

  refInput = React.createRef();

  render() {
    const { distributor, customers, sites, currentCustomer, editing, editField, editValue } = this.state;
    if (!distributor) {
      return <Loading />;
    }

    return (
      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/admin/">Admin</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/admin/distributors">Distributors</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Edit
            </li>
          </ol>
        </nav>
        <h5 className="mb-3">Edit Distributor</h5>
        <div className="row">
          <div className="mb-3 col-12 col-md-6 col-lg-4">
            <div className="card border-info">
              <div className="card-header bg-info text-white">
                <strong>Detail</strong>
              </div>
              <div className="card-body">
                <dl className="row">
                  <dt className="col-12 col-md-4 col-lg-3">Code</dt>
                  <dd
                    className="col-12 col-md-8 col-lg-9"
                    onClick={e => this.setState({ editField: 'code', editValue: distributor.code, editing: true })}
                  >
                    {(!editing || editField !== 'code') && <DescriptionEdit>{distributor.code}</DescriptionEdit>}
                    {editing && editField === 'code' && (
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        value={editValue}
                        ref={this.refInput}
                        onBlur={this.saveEdit}
                        onKeyDown={e => handleOnKeyDown(e, this.saveEdit)}
                        onChange={e => this.setState({ editValue: e.target.value })}
                      />
                    )}
                  </dd>
                  <dt className="col-12 col-md-4 col-lg-3">Description</dt>
                  <dd
                    className="col-12 col-md-8 col-lg-9"
                    onClick={e =>
                      this.setState({ editField: 'description', editValue: distributor.description, editing: true })
                    }
                  >
                    {(!editing || editField !== 'description') && (
                      <DescriptionEdit>
                        <ReactMarkdown source={distributor.description} />
                      </DescriptionEdit>
                    )}
                    {editing && editField === 'description' && (
                      <div>
                        <span>
                          Use Markdown:{' '}
                          <a
                            href="https://en.support.wordpress.com/markdown-quick-reference/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Help
                          </a>
                        </span>
                        <textarea
                          className="form-control form-control-sm"
                          value={editValue}
                          autoCorrect="off"
                          rows={5}
                          ref={this.refInput}
                          onBlur={this.saveEdit}
                          onChange={e => this.setState({ editValue: e.target.value })}
                        />
                      </div>
                    )}
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div className="mb-3 col-12 col-md-6 col-lg-4">
            <div className="card border-info">
              <div className="card-header bg-info text-white">
                <strong>Customers</strong>
              </div>
              <div className="card-body">
                <div className="list-group">
                  {customers.rows.map((customer, i) => {
                    return (
                      <div
                        key={i}
                        className={
                          'list-group-item list-group-item-action d-flex justify-content-between align-items-center' +
                          (currentCustomer === customer.id ? ' active' : '')
                        }
                        onClick={e => this.loadSites(customer.id)}
                        style={{ cursor: 'pointer' }}
                      >
                        {customer.code}{' '}
                        <Link
                          className={'btn ' + (currentCustomer === customer.id ? 'btn-primary' : 'btn-link')}
                          to={`/admin/customers/${customer.id}`}
                          title="Edit Customer"
                        >
                          <Octicon icon={IconLink} size="small" />
                        </Link>
                      </div>
                    );
                  })}
                  {customers.rows.length === 0 && (
                    <div className="list-group-item">
                      <em>No Customers found.</em>
                    </div>
                  )}
                </div>
                <div className="mt-3">
                  <Link
                    className="btn btn-info"
                    to={`/admin/customers/create?distributor_id=${distributor.id}`}
                    title="Add new customer"
                  >
                    <Octicon icon={Plus} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {currentCustomer > 0 && (
            <div className="mb-3 col-12 col-md-6 col-lg-4">
              <div className="card border-info">
                <div className="card-header bg-info text-white">
                  <strong>Sites</strong>
                </div>
                <div className="card-body">
                  <div className="list-group">
                    {sites.rows.map((site, i) => {
                      return (
                        <div
                          key={i}
                          className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                        >
                          {site.code} {site.description}
                          <Link className="btn btn-link" to={`/admin/sites/${site.id}`} title="Edit Site">
                            <Octicon icon={IconLink} size="small" />
                          </Link>
                        </div>
                      );
                    })}
                    {sites.rows.length === 0 && (
                      <div className="list-group-item">
                        <em>No Sites found.</em>
                      </div>
                    )}
                  </div>
                  <div className="mt-3">
                    <Link
                      className="btn btn-info"
                      to={`/admin/sites/create?customer_id=${currentCustomer}`}
                      title="Add new site"
                    >
                      <Octicon icon={Plus} />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="row">
          <div className="mb-3 col-12 col-md-6 col-lg-4">
            <div className="card border-danger">
              <div className="card-header bg-danger text-white">
                <strong>DANGER ZONE</strong>
              </div>
              <div className="card-body">
                <button className="btn btn-danger" onClick={this.handleDelete}>
                  DELETE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.loadDistributor();
    this.loadCustomers();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.distributor && this.state.distributor.id && !prevState.distributor.id) {
      setTitle(this.state.distributor.code);
    }
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.loadDistributor();
    }
    if (!prevState.editing && this.state.editing && this.refInput.current) {
      this.refInput.current.focus();
    }
  }

  handleDelete = e => {
    e.preventDefault();
    const ok = window.confirm('Are you sure?');
    if (ok) {
      const { distributor } = this.state;
      Distributors.delete(distributor.id)
        .then(() => {
          this.props.history.push('/admin/distributors');
        })
        .catch(e => {
          ltToast('Unable to delete entity: ' + e.message, 5000, true);
        });
    }
  };

  saveEdit = () => {
    const { editField, editValue, distributor } = this.state;
    this.setState({ editField: '', editValue: '', editing: false });
    this.refInput = React.createRef();
    if (distributor[editField] !== editValue) {
      Distributors.update(distributor.id, editField, editValue)
        .then(distributor => {
          this.setState({ distributor });
        })
        .catch(e => {
          ltToast('Unable to save: ' + e.message, 5000, true);
        });
    }
  };

  loadDistributor = () => {
    const { match } = this.props;
    const { params } = match;
    Distributors.get(params.id)
      .then(distributor => {
        this.setState({
          distributor
        });
      })
      .catch(e => console.error('err', e.message));
  };

  loadCustomers = () => {
    const { match } = this.props;
    const { params } = match;
    Customers.find({ search: `distributor_id:${params.id}` }).then(customers => this.setState({ customers }));
  };

  loadSites = id => {
    Sites.find({ search: `customer_id:${id}` }).then(sites => this.setState({ sites, currentCustomer: id }));
  };
}

export default DistributorView;
