import { RECEIVE_SITES, REQUEST_SITES, FAILED_SITES } from './sitesActions';

const initialState = {
  isFetching: false,
  data: undefined
};

export default function sitesReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_SITES:
      return Object.assign({}, state, { isFetching: true });

    case RECEIVE_SITES:
      return Object.assign({}, state, { isFetching: false, data: action.data });

    case FAILED_SITES:
      return Object.assign({}, state, { isFetching: false, data: false });

    default:
      return state;
  }
}
