import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import CategoryCreate from './CategoryCreate';
import Category from './CategoryMain';
import CategoryView from './CategoryView';

class CategoryRoot extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  render() {
    const { match } = this.props;
    return (
      <div className="entity-wrapper mt-3">
        <Switch>
          <Route exact path={match.path} component={Category} />
          <Route exact path={`${match.path}/create`} component={CategoryCreate} />
          <Route exact path={`${match.path}/:id`} component={CategoryView} />
        </Switch>
      </div>
    );
  }
}

export default CategoryRoot;
