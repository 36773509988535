export const copyToClipboard = (str, next) => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  next && next(true);
};

export const handleOnKeyDown = (e, f) => {
  if (e.key === 'Enter') {
    f();
  }
};
