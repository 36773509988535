import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { setTitle } from '../../lib/utils/windowutils';
import Examples from '../../lib/models/Examples';
import qs from 'qs';
import Octicon, { Trashcan, Search, Plus } from '@githubprimer/octicons-react';
import { handleOnKeyDown } from '../../lib/utils/commonutils';
import PropTypes from 'prop-types';
import { ltToast } from '../Common/LTToast';
class ExampleMain extends PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    entities: { rows: [] },
    searchString: ''
  };

  render() {
    const { match, history } = this.props;
    const { entities, searchString } = this.state;
    const { offset } = this.getQueryParameters();
    return (
      <div>
        <div className="my-3 row">
          <div className="col-12 col-md-4 col-lg-3">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" onClick={this.handleSearch}>
                  <Octicon icon={Search} size="small" />
                </span>
              </div>
              <input
                type="text"
                value={searchString}
                onChange={e => this.setState({ searchString: e.target.value })}
                onKeyDown={e => handleOnKeyDown(e, this.handleSearch)}
                className="form-control form-control-sm"
                placeholder="Search"
              />
              <div className="input-group-append">
                <button
                  disabled={searchString === ''}
                  className="btn btn-sm btn-outline-danger"
                  onClick={e => history.push(match.url)}
                >
                  <Octicon icon={Trashcan} size="small" />
                </button>
              </div>
            </div>
          </div>
        </div>
        {entities.rows.length > 0 && (
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>Title</th>
                <th>Tags</th>
              </tr>
            </thead>
            <tbody>
              {entities.rows.map((example, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <Link to={`${match.path}/${example.id}`}>#{example.id}</Link>
                    </td>
                    <td>{example.title}</td>
                    <td>
                      {example.tags.map((tag, i) => {
                        return (
                          <Link key={i} to={`${match.path}?q=tag:${tag}`}>
                            <span key={i} className="badge badge-primary mr-2">
                              {tag}
                            </span>
                          </Link>
                        );
                      })}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        {entities.rows.length === 0 && <div className="alert alert-info">No example found</div>}
        <div className="mt-3">
          <Link className="btn btn-info" to={`${match.path}/create`} title="Add new example">
            <Octicon icon={Plus} />
          </Link>
        </div>
        {(Number(offset) || entities.hasMore) && (
          <div className="mt-3 ml-auto p-2 bd-highlight">
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li className={'page-item' + (!Number(offset) ? ' disabled' : '')}>
                  <Link to={this.getPaginationUrl('p')} className="page-link" onClick={e => e.target.blur()}>
                    Previous
                  </Link>
                </li>
                <li className={'page-item' + (entities.hasMore === false ? ' disabled' : '')}>
                  <Link to={this.getPaginationUrl('n')} className="page-link" onClick={e => e.target.blur()}>
                    Next
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </div>
    );
  }

  componentDidMount() {
    setTitle('Example');
    this.loadExamples();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.search !== this.props.location.search) {
      this.loadExamples();
    }
  }

  loadExamples = () => {
    const { search } = this.props.location;
    const parameters = qs.parse(search, { ignoreQueryPrefix: true });
    const { limit, offset, order_by, sort, q } = parameters;

    let searchString = '';
    if (q) {
      searchString = q;
    }
    /*
        q.split(' ').forEach(search => {
          if (search.indexOf(':') < 0) {
            searchString += search;
          } else {

          }
        });
      }
       */
    if (this.state.searchString !== searchString) {
      this.setState({ searchString });
    }

    Examples.find({ limit, offset, order_by, sort, search: q })
      .then(entities => {
        this.setState({ entities });
      })
      .catch(e => {
        ltToast('Ops, unable to load examples. Please retry again later', 5000, true);
      });
  };

  handleSearch = () => {
    const { pathname, search } = this.props.location;
    const parameters = qs.parse(search, { ignoreQueryPrefix: true });
    parameters.q = this.state.searchString;
    parameters.offset = 0;
    this.props.history.push(pathname + qs.stringify(parameters, { addQueryPrefix: true }));
  };

  getQueryParameters = () => {
    const { search } = this.props.location;
    return qs.parse(search, { ignoreQueryPrefix: true });
  };

  getPaginationUrl = direction => {
    const { pathname } = this.props.location;
    const parameters = this.getQueryParameters();
    let offset = Number(parameters.offset) || 0;
    let limit = Number(parameters.limit) || 10;
    let offsetPrev = offset - limit;
    let offsetNext = offset + limit;
    if (offsetPrev < 0) {
      offsetPrev = 0;
    }
    parameters.offset = direction === 'p' ? offsetPrev : offsetNext;
    return pathname + qs.stringify(parameters, { addQueryPrefix: true });
  };
}

export default ExampleMain;
