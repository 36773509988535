import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Octicon, { Dash, Plus, Trashcan, CloudDownload } from '@githubprimer/octicons-react';
import Entities from '../../lib/models/Entities';
import Functionalities from '../../lib/models/Functionalities';
import { ltToast } from '../Common/LTToast';
import { Link } from 'react-router-dom';

class FunctionalityEntitiesCard extends PureComponent {
  static propTypes = {
    functionality: PropTypes.object.isRequired,
    onFunctionalityChange: PropTypes.func.isRequired
  };

  inputRef = React.createRef();

  state = {
    editValue: '',
    editing: false,
    entitiesFound: [],
    idxEntity: -1
  };

  searchTimeout;

  render() {
    const { functionality } = this.props;
    const { editing, editValue, entitiesFound, idxEntity } = this.state;
    return (
      <div className="card border-info">
        <div className="card-header bg-info text-white">
          <strong>Entities</strong>
        </div>
        <div className="card-body">
          {functionality.entities.length === 0 && (
            <div>
              <p>No linked entities</p>
            </div>
          )}
          {functionality.entities.length > 0 && (
            <table className="table table-sm">
              <tbody>
                {functionality.entities.map((entity, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <Link to={`/entities/${entity.id}`}>{entity.title}</Link>
                        <br />
                        <small>
                          <em>
                            Added by {entity.created_by}, {new Date(entity.created_at).toLocaleString()}
                          </em>
                        </small>
                      </td>
                      <td>
                        <button
                          className="btn btn-link"
                          onClick={e => {
                            this.deleteEntity(entity.id);
                          }}
                        >
                          <Octicon icon={Trashcan} size="small" />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          {editing && (
            <div className="mt-3">
              <input
                type="text"
                ref={this.inputRef}
                className="form-control form-control-sm"
                onKeyDown={this.handleKeyDown}
                value={editValue}
                onChange={this.handleChange}
              />
              {editValue.length > 1 && (
                <div className="dropdown">
                  <div className="dropdown-menu show">
                    {entitiesFound.map((entity, i) => {
                      return (
                        <div
                          onClick={e => this.addEntity(entity.id)}
                          className={'dropdown-item' + (idxEntity === i ? ' active' : '')}
                          key={i}
                        >
                          {entity.title}
                        </div>
                      );
                    })}
                    {entitiesFound.length === 0 && (
                      <div className="dropdown-item">
                        <em>No entities found...</em>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="mt-3" title="Add Entity">
            <button onClick={this.toggleEntity} className="btn btn-info">
              <Octicon icon={editing ? Dash : Plus} size="small" />
            </button>

            {functionality.entities.length > 0 && (
              <div className="float-right">
                <a
                  href={Functionalities.gtContentUrl(functionality.id)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-info"
                >
                  <Octicon icon={CloudDownload} size="small" />
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevState.editing && this.state.editing) {
      this.inputRef.current.focus();
    }
  }

  downloadContent = e => {
    e.preventDefault();
    const { functionality } = this.props;
    Functionalities.gtContent(functionality.id)
      .then(res => console.log(res))
      .catch(e => console.error(e.message));
  };

  deleteEntity = entity_id => {
    const { functionality, onFunctionalityChange } = this.props;
    Functionalities.deleteEntity(functionality.id, entity_id)
      .then(functionality => {
        onFunctionalityChange(functionality);
      })
      .catch(e => {
        ltToast('Unable to delete entity: ' + e.message, 5000, true);
      });
  };

  handleKeyDown = e => {
    const { idxEntity, entitiesFound } = this.state;
    // arrow up/down button should select next/previous list element
    if (e.keyCode === 27) {
      this.setState({ editValue: '', idxEntity: -1 });
    } else if (e.keyCode === 38 && idxEntity > 0) {
      console.log('up');
      this.setState(prevState => ({
        idxEntity: prevState.idxEntity - 1
      }));
    } else if (e.keyCode === 40 && idxEntity < entitiesFound.length - 1) {
      e.preventDefault();
      this.setState(prevState => ({
        idxEntity: prevState.idxEntity + 1
      }));
    } else if (e.keyCode === 13 && idxEntity >= 0) {
      e.preventDefault();
      this.addEntity(entitiesFound[idxEntity].id);
    }
  };

  addEntity = entity_id => {
    const { functionality, onFunctionalityChange } = this.props;
    Functionalities.addEntity(functionality.id, entity_id)
      .then(functionality => {
        this.setState({ idxEntity: -1, entitiesFound: [], editValue: '' }, () => {
          onFunctionalityChange(functionality);
        });
      })
      .catch(e => {
        ltToast('Unable to add entity: ' + e.message, 5000, true);
      });
  };

  handleChange = e => {
    this.setState({ editValue: e.target.value, idxEntity: -1 }, () => {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      if (this.state.editValue.length > 2) {
        this.searchTimeout = setTimeout(this.searchEntities, 300);
      }
    });
  };

  searchEntities = () => {
    const { functionality } = this.props;
    const { editValue } = this.state;
    if (!editValue) {
      this.setState({ entitiesFound: [], idxEntity: -1 });
      return;
    }
    Entities.find({ search: editValue }).then(entities => {
      const es = entities.rows.filter(e => {
        let ok = true;
        functionality.entities.forEach(en => {
          if (!ok) return;
          if (en.id === e.id) {
            ok = false;
          }
        });
        return ok;
      });
      this.setState({ entitiesFound: es });
    });
  };

  toggleEntity = () => {
    if (this.state.editing) {
      this.setState({ editValue: '', editing: false });
    } else {
      this.setState({ editValue: '', editing: true });
    }
  };
}

export default FunctionalityEntitiesCard;
