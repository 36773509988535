import React, { PureComponent } from 'react';
import { setTitle } from '../../lib/utils/windowutils';
import Domains from '../../lib/models/Domains';
import PropTypes from 'prop-types';
import qs from 'qs';
import { ltToast } from '../Common/LTToast';
import Octicon, { CloudUpload, Dash, Plus, Search, Trashcan } from '@githubprimer/octicons-react';
import { handleOnKeyDown } from '../../lib/utils/commonutils';
import { Link } from 'react-router-dom';

class Domain extends PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    validated: false,
    domains: { rows: [] },
    domain: '',
    access_type: '',
    searchString: '',
    adding: false
  };

  render() {
    const { match, history } = this.props;
    const { domains, searchString, adding, validated, domain, access_type } = this.state;
    const { offset } = this.getQueryParameters();
    return (
      <div>
        <div className="my-3 row">
          <div className="col-12 col-md-4 col-lg-3">
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" onClick={this.handleSearch}>
                  <Octicon icon={Search} size="small" />
                </span>
              </div>
              <input
                type="text"
                value={searchString}
                onChange={e => this.setState({ searchString: e.target.value })}
                onKeyDown={e => handleOnKeyDown(e, this.handleSearch)}
                className="form-control form-control-sm"
                placeholder="Search"
              />
              <div className="input-group-append">
                <button
                  disabled={searchString === ''}
                  className="btn btn-sm btn-outline-danger"
                  onClick={e => history.push(match.url)}
                >
                  <Octicon icon={Trashcan} size="small" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <h5 className="mt-3">Domains</h5>

        {domains.rows.length > 0 && (
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th>Domain</th>
                <th>Access Type</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {domains.rows.map((domain, i) => {
                return (
                  <tr key={i} className="justify-content-between align-items-center">
                    <td><Link to={`${match.path}/${domain.id}`}>{domain.domain}</Link></td>
                    <td>{domain.access_type}</td>
                    <td>{domain.status ? 'Enabled' : 'Disabled'}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        {domains.rows.length === 0 && <div className="alert alert-info">No domain found</div>}
        {adding && (
          <div className="row">
            <div className="col-12 col-md-6 col-lg-3">
              <form onSubmit={this.handleSubmit} className="mb-3 mt-3">
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    id="domainInputCode"
                    name="domain"
                    placeholder="Enter Domain"
                    autoComplete="off"
                    value={domain}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-group">
                  <select
                    className="form-control"
                    name="access_type"
                    placeholder="Enter Domain"
                    autoComplete="off"
                    value={access_type}
                    onChange={this.handleChange}
                  >
                    <option value="" disabled={true}>
                      Select access type
                    </option>
                    <option value="full">Full</option>
                    <option value="whitelist">Whitelist</option>
                  </select>
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    <button type="submit" className="btn btn-lg btn-info" disabled={!validated}>
                      <Octicon icon={CloudUpload} size="small" title="Save" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
        <div className="d-flex bd-highlight mb-3">
          <div className="mt-3" title="Add Domain">
            <button onClick={this.toggleDomain} className="btn btn-info">
              <Octicon icon={adding ? Dash : Plus} size="small" />
            </button>
          </div>
          {(Number(offset) || domains.hasMore) && (
            <div className="mt-3 ml-auto p-2 bd-highlight">
              <nav aria-label="Page navigation example">
                <ul className="pagination">
                  <li className={'page-item' + (!Number(this.getQueryParameters().offset) ? ' disabled' : '')}>
                    <Link to={this.getPaginationUrl('p')} className="page-link" onClick={e => e.target.blur()}>
                      Previous
                    </Link>
                  </li>
                  <li className={'page-item' + (domains.hasMore === false ? ' disabled' : '')}>
                    <Link to={this.getPaginationUrl('n')} className="page-link" onClick={e => e.target.blur()}>
                      Next
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          )}
        </div>
      </div>
    );
  }

  componentDidMount() {
    setTitle('Domains');
    this.loadDomains();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.search !== this.props.location.search) {
      this.loadDomains();
    }
    const { domain, access_type } = this.state;
    let validated = false;
    if (domain && access_type) {
      validated = true;
    }
    this.setState({ validated });
  }

  loadDomains = () => {
    const { search } = this.props.location;
    const parameters = qs.parse(search, { ignoreQueryPrefix: true });
    const { limit, offset, order_by, sort, q } = parameters;

    let searchString = '';
    if (q) {
      searchString = q;
    }
    /*
        q.split(' ').forEach(search => {
          if (search.indexOf(':') < 0) {
            searchString += search;
          } else {

          }
        });
      }
       */
    if (this.state.searchString !== searchString) {
      this.setState({ searchString });
    }

    Domains.find({ limit, offset, order_by, sort, search: q }).then(domains => {
      this.setState({ domains });
    });
  };

  handleSearch = () => {
    const { pathname, search } = this.props.location;
    const parameters = qs.parse(search, { ignoreQueryPrefix: true });
    parameters.q = this.state.searchString;
    parameters.offset = 0;
    this.props.history.push(pathname + qs.stringify(parameters, { addQueryPrefix: true }));
  };

  handleDelete = (e, code) => {
    const ok = window.confirm('Are you sure?');
    e.target.blur();
    if (ok) {
      setTimeout(() => {
        Domains.delete(code)
          .then(() => {
            this.loadDomains();
          })
          .catch(e => {
            ltToast('Unable to delete domain: ' + e.message, 5000, true);
          }, 300);
      });
    }
  };
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { domain, access_type, validated } = this.state;
    if (!validated) {
      return;
    }
    const payload = { domain, access_type };
    Domains.create(payload)
      .then(() => {
        this.loadDomains();
      })
      .then(() => {
        this.toggleDomain();
      })
      .catch(err => {
        ltToast('Unable to save: ' + err.message, 5000, true);
      });
    return false;
  };

  toggleDomain = () => {
    this.setState({ adding: !this.state.adding });
  };

  getQueryParameters = () => {
    const { search } = this.props.location;
    return qs.parse(search, { ignoreQueryPrefix: true });
  };

  getPaginationUrl = direction => {
    const { pathname } = this.props.location;
    const parameters = this.getQueryParameters();
    let offset = Number(parameters.offset) || 0;
    let limit = Number(parameters.limit) || 10;
    let offsetPrev = offset - limit;
    let offsetNext = offset + limit;
    if (offsetPrev < 0) {
      offsetPrev = 0;
    }
    parameters.offset = direction === 'p' ? offsetPrev : offsetNext;
    return pathname + qs.stringify(parameters, { addQueryPrefix: true });
  };
}

export default Domain;
