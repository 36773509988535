import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { setTitle } from '../../lib/utils/windowutils';
import Examples from '../../lib/models/Examples';
import { ltToast } from '../Common/LTToast';
import Octicon, { CloudUpload } from '@githubprimer/octicons-react';
import TagInput from '../Common/TagInput';

class ExampleCreate extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired
  };

  state = {
    validated: false,
    title: '',
    description: '',
    tags: '',
    content: '',
    allTags: []
  };

  render() {
    const { title, description, tags, content, allTags, validated } = this.state;
    return (
      <div>
        <h5>Create new Example</h5>
        <form onSubmit={this.handleSubmit} className="mb-3 mt-3">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4">
              <div className="card border-info">
                <div className="card-header bg-info text-white">
                  <strong>Detail</strong>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="exampleInputTitle">Title</label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputTitle"
                      name="title"
                      placeholder="Enter title"
                      autoComplete="off"
                      value={title}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputDescription">Description</label>
                    <textarea
                      rows={5}
                      className="form-control"
                      id="exampleInputDescription"
                      name="description"
                      placeholder="Enter Description"
                      autoCorrect="off"
                      value={description}
                      onChange={this.handleChange}
                    />
                    <small>
                      Support Markdown:{' '}
                      <a
                        tabIndex={-1}
                        href="https://en.support.wordpress.com/markdown-quick-reference/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Help
                      </a>
                    </small>
                  </div>
                  <div className="form-group">
                    <label>
                      Tags{' '}
                      <small>
                        <em>Add at least 2</em>
                      </small>
                    </label>
                    <TagInput tags={allTags} values={tags} onAddTag={this.addTag} />
                    <p className="my-1">
                      {tags.split(',').map((tag, i) => (
                        <span className="badge badge-primary mr-2" key={i} onClick={e => this.deleteTag(i)}>
                          {tag.trim()}
                        </span>
                      ))}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-5 col-lg-8">
              <div className="card border-info">
                <div className="card-header text-white bg-info ">
                  <strong>Content</strong>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <textarea
                      rows={20}
                      className="form-control"
                      id="entityInputContent"
                      name="content"
                      placeholder="Enter/Paste Content"
                      autoCorrect="off"
                      value={content}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <button type="submit" className="btn btn-lg btn-info" disabled={!validated}>
                <Octicon icon={CloudUpload} size="small" title="Save" />
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }

  componentDidMount() {
    setTitle('New Example');
    Examples.getTags()
      .then(tags => {
        this.setState({ allTags: tags });
      })
      .catch(e => {
        ltToast('Unable to load tags!', 3000, true);
      });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { title, description, content, tags } = this.state;
    let validated = false;
    if (title && description && content && tags.split(',').filter(t => !!t.trim()).length > 1) {
      validated = true;
    }
    this.setState({ validated });
  }

  addTag = tag => {
    let { tags } = this.state;
    if (tags !== '') {
      tags += ',';
    }
    tags += ' ' + tag;
    this.setState({ tags: tags });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { title, description, tags, content, validated } = this.state;
    if (!validated) {
      return;
    }
    const payload = {
      title,
      description,
      content,
      tags: tags.split(',').map(t => t.trim())
    };
    Examples.create(payload)
      .then(() => {
        this.props.history.push('./');
      })
      .catch(err => {
        ltToast('Unable to save: ' + err.message, 5000, true);
      });
    return false;
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
}

export default ExampleCreate;
