import { deleteData, getData, postData, putData } from '../utils/httputils';

class Examples {
  static apiPath = '/api/v1/examples';

  static create(data) {
    return postData(Examples.apiPath, data);
  }

  static find(params) {
    const { limit, offset, order_by, sort, search } = params;
    const searchQuery = search ? '&search=' + search : '';
    return getData(
      `${Examples.apiPath}?limit=${limit || 10}&offset=${offset || 0}&order_by=${order_by || 'id'}&sort=${sort ||
        'desc'}${searchQuery}`
    );
  }

  static get(id) {
    return getData(`${Examples.apiPath}/${id}`);
  }

  static update(id, field, value) {
    return putData(`${Examples.apiPath}/${id}`, { field, value });
  }

  static updateTags(id, tags) {
    return postData(`${Examples.apiPath}/${id}/tags`, tags);
  }

  static updateContent(id, content) {
    return postData(`${Examples.apiPath}/${id}/content`, { content });
  }

  static deleteContent(id, content_id) {
    return deleteData(`${Examples.apiPath}/${id}/content/${content_id}`);
  }

  static getContent = (id, content_id) => {
    return getData(`${Examples.apiPath}/${id}/content/${content_id}`);
  };

  static getTags() {
    return getData(`${Examples.apiPath}/tags`);
  }

  static addSite = (id, site_id) => {
    return postData(`${Examples.apiPath}/${id}/site`, { site_id });
  };

  static deleteSite = (id, site_id) => {
    return deleteData(`${Examples.apiPath}/${id}/site/${site_id}`);
  };

  static delete = id => {
    return deleteData(`${Examples.apiPath}/${id}`);
  };
}

export default Examples;
