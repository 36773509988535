import { deleteData, getData, postData, putData } from '../utils/httputils';

class Entities {
  static apiPath = '/api/v1/entities';

  static create(data) {
    return postData(Entities.apiPath, data);
  }

  static find(params) {
    const { limit, offset, order_by, sort, search } = params;
    const searchQuery = search ? '&search=' + search : '';
    return getData(
      `${Entities.apiPath}?limit=${limit || 10}&offset=${offset || 0}&order_by=${order_by || 'id'}&sort=${sort ||
        'desc'}${searchQuery}`
    );
  }

  static get(id) {
    return getData(`${Entities.apiPath}/${id}`);
  }

  static update(id, field, value) {
    return putData(`${Entities.apiPath}/${id}`, { field, value });
  }

  static getTags() {
    return getData(`${Entities.apiPath}/tags`);
  }

  static updateTags(id, tags) {
    return postData(`${Entities.apiPath}/${id}/tags`, tags);
  }

  static updateContent(id, content, message) {
    return postData(`${Entities.apiPath}/${id}/contents`, { content, message });
  }

  static deleteContent(id, content_id) {
    return deleteData(`${Entities.apiPath}/${id}/contents/${content_id}`);
  }

  static getContent = (id, content_id) => {
    return getData(`${Entities.apiPath}/${id}/contents/${content_id}`);
  };

  static addSite = (id, site_id) => {
    return postData(`${Entities.apiPath}/${id}/sites`, { site_id });
  };

  static deleteSite = (id, site_id) => {
    return deleteData(`${Entities.apiPath}/${id}/sites/${site_id}`);
  };

  static delete = id => {
    return deleteData(`${Entities.apiPath}/${id}`);
  };
}

export default Entities;
