import Settings from './settings';

export const REQUEST_CONFIG = 'REQUEST_CONFIG';
export const RECEIVE_CONFIG = 'RECEIVE_CONFIG';
export const FAILED_CONFIG = 'FAILED_CONFIG';

export function doGetConfig() {
  return dispatch => {
    dispatch(requestConfig());
    return Settings.getConfig()
      .then(json => {
        dispatch(receiveConfig(json));
      })
      .catch(error => dispatch(failedConfig(error.message)));
  };
}

function requestConfig() {
  return {
    type: REQUEST_CONFIG
  };
}

function receiveConfig(json) {
  return {
    type: RECEIVE_CONFIG,
    data: json
  };
}

function failedConfig(error) {
  console.log(error);
  return {
    type: FAILED_CONFIG
  };
}

